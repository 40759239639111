import { Colors } from "../../Types/Global";
import { Banner as BCBannerProps } from "../../generated/graphql";
import { AcfBanner } from "../../Data/dtos";

export interface BannerProps extends BCBannerProps {}

const Banner = ({
    image,
    content,
    imageLocation,
    backgroundColor,
    borderColor,
}: AcfBanner) => {
    const flip = imageLocation === "right" ? true : false;
    const textColor = ["soft-black", "salmon"].includes(backgroundColor)
        ? "soft-white"
        : "soft-black";
    return (
        <div className={`bg-bc-${backgroundColor} py-16`}>
            <div className="container mx-auto pt-8 pt-12 mt-0 lg:mt-12">
                <div
                    className={`bc-banner flex flex-col md:flex-row justify-center w-full h-auto text-bc-soft-black gap-8`}
                >
                    <div
                        className={`bc-banner-image pt-4 lg:pt-12 mt-2 lg:mt-12 w-full md:w-1/2 ${
                            flip ? "order-2" : ""
                        }`}
                    >
                        {image?.url && (
                            <img
                                src={image?.url}
                                alt={image?.altText || "banner image"}
                                className="rounded-lg"
                            />
                        )}
                    </div>
                    <div
                        className={`bc-banner-content w-full md:w-1/2 flex flex-col justify-center gap-6 text-bc-${textColor}`}
                        dangerouslySetInnerHTML={{ __html: content! }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
