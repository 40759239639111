import { ButtonHTMLAttributes } from "react";
import { Colors } from "../../Types/Global";
import { Link } from "react-router-dom";
import DownArrow from "../../assets/icons/down-arrow.svg";
import ArrowIcon from "../Arrow/Arrow";
import "./styles.scss";

interface DropDownButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    backgroundColor?: Colors;
    text: string;
    to?: string;
    isDownload?: boolean;
}

const DropDownButton = (props: DropDownButtonProps) => {
    const {
        text,
        className,
        isDownload = true,
        to,
        backgroundColor = Colors["salmon"],
        ...rest
    } = props;

    const colorClasses = `bg-bc-${backgroundColor} text-bc-soft-white`;

    if (to) {
        return (
            <Link
                to={to}
                className={`bc-dropdown-button ${
                    isDownload ? " download " : ""
                } rounded-lg py-4 px-6 items-center ${colorClasses}`}
            >
                {props.text}
                <ArrowIcon dark={true} width={`1.25rem`} />
            </Link>
        );
    }

    return (
        <button
            {...rest}
            className={`bc-dropdown-button ${
                isDownload ? " download " : ""
            } rounded-lg py-4 px-6 items-center ${colorClasses}`}
        >
            {props.text}
            <ArrowIcon dark={true} width={`1.25rem`} />
        </button>
    );
};

export default DropDownButton;
