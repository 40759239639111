import { useState, useEffect, useRef } from "react";

const AnimatedNumbers = ({
    endValue,
    start,
}: {
    endValue: number;
    start: boolean;
}) => {
    const [count, setCount] = useState(0);
    const [complete, setComplete] = useState(false);

    const animateValue = (start: number, end: number, duration: number) => {
        let startTimestamp: number | null = null;

        const easeOutQuad = (t: number) => t * (2 - t);

        const step = (timestamp: number) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min(
                (timestamp - startTimestamp) / duration,
                1
            );

            const easedProgress = easeOutQuad(progress);

            setCount(Math.floor(easedProgress * (end - start) + start));

            if (progress < 1) {
                window.requestAnimationFrame(step);
            } else {
                setComplete(true);
            }
        };
        window.requestAnimationFrame(step);
    };

    useEffect(() => {
        if (start) {
            animateValue(0, endValue, 2500);
        }
    }, [start]);

    return (
        <span className="relative">
            <span className="opacity-0">{endValue}+</span>
            <span className="absolute w-full right-0 text-right">
                {count}
                {complete ? "+" : ""}
            </span>
        </span>
    );
};

export default AnimatedNumbers;
