const BarGraphAnimation = ({ className }: { className?: string }) => {
    return (
        <iframe
            title="bar graph graphic"
            className={className}
            src="/assets/svg/bars-anim.svg"
        />
    );
};

export default BarGraphAnimation;
