import { useState, useEffect } from "react";
import { useGetLocationsQuery } from "../generated/graphql";
import DataUtilities from "./Utilities";
import { CompanyLocationData } from "./dtos";

const useGetCompanyLocationsQuery = <T>() => {
    const [locationData, setLocationData] = useState<
        CompanyLocationData[] | undefined
    >(undefined);

    const { data, loading, error } = useGetLocationsQuery();

    useEffect(() => {
        if (data && !locationData) {
            setLocationData(DataUtilities.flattenLocationData(data));
        }
    }, [data]);

    return { data: locationData, loading, error };
};

export default useGetCompanyLocationsQuery;
