import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { AcfImage } from "../../Data/dtos";
import "./styles.scss";
import { Link } from "react-router-dom";

const BrandCarousel = ({ logos }: { logos: AcfImage[] }) => {
    return (
        <div className="bc-carousel-wrapper flex h-full items-center">
            <Carousel
                infiniteLoop={true}
                centerMode={true}
                centerSlidePercentage={50}
                emulateTouch={true}
                showIndicators={false}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                renderArrowPrev={(onClickHandler, hasPrev, label) => (
                    <button
                        className="bc-carousel-arrow bc-carousel-arrow--prev invisible md:visible"
                        onClick={onClickHandler}
                        title={`Previous`}
                        type="button"
                    />
                )}
                renderArrowNext={(onClickHandler, hasPrev, label) => (
                    <button
                        className="bc-carousel-arrow bc-carousel-arrow--next invisible md:visible"
                        onClick={onClickHandler}
                        title={`Next`}
                        type="button"
                    />
                )}
            >
                {logos.map((brand, index) => (
                    <div className="slide" key={index}>
                        <img src={brand.url} alt={brand.altText} />
                    </div>
                ))}
            </Carousel>
            <Link
                to="/services#partners"
                className="bc-carousel-link hover:text-bc-bright-blue"
                title="See all of our partners"
            >
                Click here to see all our partners
            </Link>
        </div>
    );
};

export default BrandCarousel;
