// AKA: Culture Page
import { useState, useEffect } from "react";
import BasePage from "./Base";
import { Colors } from "../Types/Global";
import { Helmet } from "react-helmet-async";
import parse from "html-react-parser";
import Section from "../Components/Section/Section";
import Banner from "../Components/Banners/Banner";
import GetAccess from "../Components/GetAccess/GetAccess";
import NewsletterSignup from "../Components/NewsletterSignup/NewsletterSignup";
import GetInTouch from "../Components/GetInTouch/GetInTouch";
import Loading from "../Components/Loading/Loading";
import NewsItems from "../Components/NewsItems/NewsItems";
import Spacer from "../Components/Spacer/Spacer";
import DataUtilities from "../Data/Utilities";
import CoreValues from "../Components/CoreValues/CoreValues";
import CompanyBenefits from "../Components/CompanyBenefits/CompanyBenefits";
import CultureImageCarousel from "../Components/CultureImageCarousel/CultureImageCarousel";
import { useGetAboutPageDataQuery } from "../generated/graphql";
import TalkIconRed from "../assets/icons/talkie-red.svg";
import {
    AcfImage,
    AcfLegacyBanner,
    AcfCompanyBenefits,
    AcfCoreValues,
    AcfImageCarousel,
    AcfNews,
    AcfCenteredText,
    AcfBanner,
    AcfJoinTeam,
} from "../Data/dtos";
import { join } from "path";
import Button from "../Components/Buttons/Button";

const About = () => {
    const { data, loading, error } = useGetAboutPageDataQuery();

    const [pageData, setPageData] = useState<any>(null);
    const [seoData, setSeoData] = useState<{
        fullHead: string;
        title: string;
    } | null>(null);

    useEffect(() => {
        if (data && !error) {
            const blocks = (data?.nodeByUri as any)?.editorBlocks;
            console.log(blocks);
            setPageData(DataUtilities.flattenEditorBlocks(blocks));
            setSeoData((data?.nodeByUri as any).seo);
        }
    }, [data, error]);

    const bannerData: AcfBanner = pageData?.find(
        (block: any) => block.name === "acf/banner"
    );
    const centeredTextData: AcfCenteredText = pageData?.find(
        (block: any) => block.name === "acf/centered-text"
    );
    const newsData: AcfNews = pageData?.find(
        (block: any) => block.name === "acf/news"
    );
    const aboutCtaData = pageData?.find(
        (block: any) => block.name === "acf/about-cta"
    );
    const legacyBannerData: AcfLegacyBanner = pageData?.find(
        (block: any) => block.name === "acf/legacy-banner"
    );

    const companyBenefitsData: AcfCompanyBenefits = pageData?.find(
        (block: any) => block.name === "acf/company-benefits"
    );

    const coreValuesData: AcfCoreValues = pageData?.find(
        (block: any) => block.name === "acf/core-values"
    );

    const imageCarouselData: AcfImageCarousel = pageData?.find(
        (block: any) => block.name === "acf/image-carousel"
    );

    const joinOurTeamData: AcfJoinTeam = pageData?.find(
        (block: any) => block.name === "acf/join-team"
    );

    console.log(companyBenefitsData, imageCarouselData, coreValuesData);

    if (loading) {
        return <Loading />;
    }

    return (
        <BasePage>
            <Helmet>
                <title>{seoData?.title}</title>
                {parse(seoData?.fullHead || "")}
            </Helmet>

            <Banner {...bannerData} />

            <Section>
                <div className="py-12">
                    <div className="container mx-auto py-4 px-4 max-w-2xl">
                        <h4>{centeredTextData?.superTitle}</h4>
                    </div>
                    <div
                        className="container mx-auto py-4 px-4 max-w-2xl serif"
                        dangerouslySetInnerHTML={{
                            __html: centeredTextData?.content,
                        }}
                    ></div>
                </div>
            </Section>

            <Spacer size="sm" />

            <Section>
                <CultureImageCarousel {...imageCarouselData} />
            </Section>

            <Section>
                <CoreValues {...coreValuesData} />
            </Section>

            <Section>
                <CompanyBenefits {...companyBenefitsData} />
            </Section>

            {/* Insight downloads */}
            {/* <Section>
                <div className="container mx-auto py-12 border border-bc-soft-black rounded-lg">
                    <div className="flex flex-col md:flex-row justify-between gap-8 items-center">
                        <div className="w-full md:w-1/3 p-4 lg:p-8">
                            <h3 className="text-bc-salmon">
                                {aboutCtaData?.header}
                            </h3>
                        </div>
                        <div className="w-full md:w-1/3">
                            <GetAccess
                                title={aboutCtaData?.reports?.header}
                                superTitle={aboutCtaData?.reports?.superTitle}
                                copy={aboutCtaData?.reports?.content}
                                buttonText={aboutCtaData?.reports?.accessText}
                            />
                        </div>
                        <div className="w-full md:w-1/3">
                            <NewsletterSignup />
                        </div>
                    </div>
                </div>
            </Section> */}

            <Spacer size="sm" />

            <GetInTouch {...joinOurTeamData} />

            {/* News Section */}
            <Section
                backgroundColor={Colors["main-neutral"]}
                staggeredBackgroundColor={Colors["soft-white"]}
            >
                <div className="container mx-auto py-12">
                    <h1 className="my-6">{newsData?.header}</h1>

                    <NewsItems
                        items={newsData?.news || []}
                        link={newsData?.link}
                    />
                </div>
            </Section>

            {/* Agency Info */}
            <Section gutter={false}>
                <div className="container mx-auto py-12 border-2 border-bc-soft-black rounded-lg p-12">
                    <header>
                        <div className="max-w-lg">
                            <h4>Our Legacy</h4>
                            <h3 className="mt-4 mb-6">
                                Unifying for greatness
                            </h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: legacyBannerData?.content,
                                }}
                            ></div>
                        </div>
                    </header>
                    <div className="mt-14 py-8">
                        <div className="flex gap-8 border-t border-b border-bc-soft-black py-8 justify-around items-center flex-wrap xl:flex-nowrap">
                            {legacyBannerData?.logos?.map(
                                (logo: AcfImage, index: number) => (
                                    <img
                                        className="legacy-logos"
                                        style={{
                                            maxWidth: "10rem",
                                            height: "auto",
                                        }}
                                        src={logo?.url}
                                        alt={logo?.altText}
                                        key={`legacy-logo-${index}`}
                                    />
                                )
                            )}
                        </div>
                    </div>
                </div>
            </Section>

            <Spacer size="sm" />
        </BasePage>
    );
};

export default About;
