import { Link } from "react-router-dom";

import "./styles.scss";

const FooterNav = () => {
    return (
        <nav className="bc-footer-nav">
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/services">Services</Link>
                </li>
                <li>
                    <Link to="/culture">Culture</Link>
                </li>
                <li>
                    <Link to="/connect">Connect</Link>
                </li>
                <li>
                    <Link to="/news">News</Link>
                </li>
            </ul>
        </nav>
    );
};

export default FooterNav;
