import { ReactNode, useState } from "react";
import { Link, LinkProps } from "react-router-dom";
import ArrowIcon from "../Arrow/Arrow";
import "./styles.scss";

interface ButtonProps extends LinkProps {
    type?: "text-link" | "button" | "submit";
    size: "sm" | "md" | "lg";
    dark?: boolean;
    arrowStrokeClass?: string;
    arrowStrokeHoverClass?: string;
    newsTitleGradient?: boolean;
    clip?: boolean;
    text: string;
    badge?: ReactNode; //basically used for the "fresh" sticker
    external?: boolean;
}

const Button = ({
    type = "text-link",
    size,
    to,
    text,
    external,
    arrowStrokeClass,
    arrowStrokeHoverClass,
    newsTitleGradient = false,
    badge,
    clip = false,
    dark = false,
    ...props
}: ButtonProps) => {
    const { className } = props;
    const [hovering, setHovering] = useState(false);

    const classes = [
        className ? className : "",
        "bc-button",
        newsTitleGradient ? "news-title-gradient" : "",
        clip ? "clip" : "",
        dark ? "dark" : "",
        `bc-button-${size}`,
    ].join(" ");

    return (
        <Link
            {...props}
            to={to}
            className={classes}
            target={external ? "_blank" : ""}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            <p>{text}</p>
            <span className="flex gap-1">
                {badge && badge}
                <ArrowIcon
                    strokeColorClass={
                        arrowStrokeClass
                            ? hovering
                                ? arrowStrokeHoverClass
                                : arrowStrokeClass
                            : undefined
                    }
                    dark={dark}
                    width={"1.5rem"}
                />
            </span>
        </Link>
    );
};

export default Button;
