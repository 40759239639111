import { HTMLProps, useState } from "react";

interface ArrowIconProps extends HTMLProps<HTMLElement> {
    dark?: boolean;
    flipped?: boolean;
    strokeColorClass?: string;
    strokeColorHoverClass?: string;
    width?: string | number;
}

const ArrowIcon = ({
    dark,
    flipped,
    strokeColorClass,
    strokeColorHoverClass,
    width,
    ...props
}: ArrowIconProps) => {
    const [hovering, setHovering] = useState(false);

    const getStrokeClass = () => {
        if (hovering && strokeColorHoverClass) {
            return strokeColorHoverClass;
        }

        return strokeColorClass
            ? strokeColorClass
            : dark
            ? "stroke-bc-soft-white"
            : "stroke-bc-bright-blue";
    };

    const styles = {
        width: width ? width : "auto",
        height: width ? width : "auto",
        // transform: flipped ? "rotate(180deg)" : "rotate(0deg)",
    };

    return (
        <span
            className="flex items-center justify-center"
            style={
                flipped
                    ? {
                          transformOrigin: "center center",
                          transform: "rotate(180deg)",
                      }
                    : {}
            }
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22.03 21.95"
                style={styles}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                className={`arrow-icon ${getStrokeClass()}`}
            >
                <g fill="none" strokeWidth="3">
                    <path d="M9.99 1.06l9.92 9.91-9.92 9.92M19.48 10.97H0h19.48" />
                </g>
            </svg>
        </span>
    );
};

//stroke={strokeColor}

export default ArrowIcon;
