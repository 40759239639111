import { useState, useEffect } from "react";
import ArrowIcon from "../Arrow/Arrow";
import FancyButton from "../Buttons/FancyButton";
import Button from "../Buttons/Button";
import { Link } from "react-router-dom";
import "./styles.scss";

const Luminate = () => {
    return (
        <div className="luminate-banner-wrapper">
            <div className=" bg-bc-deep-blue text-white py-3 px-2">
                <div className="container mx-auto flex justify-between  items-center">
                    <div>
                        <p>It's here! Are you ready to Luminate?</p>
                    </div>
                    <div></div>
                    <div className="hidden lg:block">
                        <p>
                            <Button
                                className="ticker-btn"
                                size="sm"
                                to={
                                    "/news/ready-for-walmarts-transition-to-luminate/"
                                }
                                arrowStrokeClass="stroke-bc-soft-white"
                                arrowStrokeHoverClass="stroke-bc-soft-white"
                                text={
                                    "Reach out to our Intelligence team today"
                                }
                            />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Luminate;
