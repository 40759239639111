import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import BasePage from "./Base";
import {
    EditorBlock,
    useGetResourcesPageDataQuery,
} from "../generated/graphql";
import Loading from "../Components/Loading/Loading";

import { useModals, Modal } from "../Providers/Modal";
import ResourcesList from "../Components/ResourcesList/ResourcesList";
import SpecialBanner from "../Components/SpecialBanner/SpecialBanner";
import DataUtilities from "../Data/Utilities";
import { AcfResourcesBanner, AcfResources } from "../Data/dtos";
// import { NewsPost } from "../Data/dtos";

const Resources = () => {
    const { data, loading, error } = useGetResourcesPageDataQuery();
    const Modals = useModals();

    const [resourcePageData, setResourcePageData] = useState<any>(null);
    const [pageTitle, setPageTitle] = useState<string | null>(null);

    useEffect(() => {
        if (data && !error) {
            const title = (data?.nodeByUri as any)?.title;
            console.log(title);
            setPageTitle(title);
            const blocks = (data?.nodeByUri as any)?.editorBlocks;
            setResourcePageData(DataUtilities.flattenEditorBlocks(blocks));
        }
    }, [data, error]);

    const bannerContent: AcfResourcesBanner = resourcePageData?.find(
        (block: any) => block.name === "acf/resources-banner"
    );

    const resourcesList: AcfResources = resourcePageData?.find(
        (block: any) => block.name === "acf/resources"
    );

    if (loading) {
        return <Loading />;
    }

    return (
        <BasePage>
            <Helmet>
                <title>Resources - The Barcode Group</title>
            </Helmet>
            <SpecialBanner
                image={bannerContent?.featuredResource?.image}
                content={bannerContent?.featuredResource?.content}
                contentTitle={bannerContent?.featuredResource?.title}
                title={pageTitle || ``}
                buttonCode={bannerContent?.featuredResource?.buttonCode}
            />

            <div className="container mx-auto">
                <ResourcesList {...resourcesList} />
                <ul className="resources-list grid grid-cols-12 grid-rows-auto gap-8 pb-12"></ul>
            </div>
        </BasePage>
    );
};

export default Resources;
