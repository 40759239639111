import { HTMLProps, useState } from "react";
import { Field, useFormikContext } from "formik";
import ArrowIcon from "../Arrow/Arrow";
import "./styles.scss";

interface InputWithSubmitButtonProps extends HTMLProps<HTMLInputElement> {
    name: string;
}

const InputWithSubmitButton = ({
    name,
    ...props
}: InputWithSubmitButtonProps) => {
    // useFormikContext hook gives us access to the formik props
    const { submitForm } = useFormikContext();
    const [focused, setFocused] = useState(false);

    return (
        <div
            className={`flex items-center border ${
                focused ? "border-bc-bright-blue" : "border-bc-soft-black"
            } rounded-lg`}
        >
            <Field
                className="input-with-button-field block w-full rounded-lg hover:placeholder-bc-bright-blue"
                onFocus={() => {
                    setFocused(true);
                }}
                onBlur={() => {
                    setFocused(false);
                }}
                name={name}
                {...props}
            />

            <button
                type="button" // Use type="button" to prevent form submission when the button is clicked
                onClick={submitForm} // This will trigger Formik's submit handler
                className="flex-shrink-0 mr-4"
                style={{
                    border: "none",
                    background: "transparent",
                    cursor: "pointer",
                    width: "1.5rem",
                }}
            >
                <ArrowIcon
                    strokeColorClass="stroke-bc-soft-black"
                    strokeColorHoverClass="stroke-bc-bright-blue"
                />
            </button>
        </div>
    );
};

export default InputWithSubmitButton;
