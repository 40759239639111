import { useState, useEffect } from "react";
import TeamFilters from "./TeamFilters";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { AcfConnectEmployees, AcfImage } from "../../Data/dtos";
import searchIcon from "../../assets/icons/search.svg";
import { EmployeeDetails, useGetEmployeesQuery } from "../../generated/graphql";

export interface FilterData {
    category: string;
    value: string;
}

export interface Filter {
    category: string;
    values: string[];
}

interface TeamMemberData {
    name: string;
    order: number;
    leadership: boolean;
    location: string;
    role?: string;
    retailers?: string[];
    services?: string[];
    title?: string;
    email: string;
    image?: AcfImage;
}

const Team = ({ data }: { data: AcfConnectEmployees }) => {
    const [filters, setFilters] = useState<FilterData[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [employeeList, setEmployeeList] = useState<TeamMemberData[]>([]);
    //filter options
    const [services, setServices] = useState<string[]>([]);
    const [locations, setLocations] = useState<string[]>([]);

    const [team, setTeam] = useState<TeamMemberData[]>([]);
    const { data: employeesData, loading, error } = useGetEmployeesQuery();

    const sortByName = (member1: TeamMemberData, member2: TeamMemberData) => {
        const n1 = member1?.name?.toLowerCase();
        const n2 = member2?.name?.toLowerCase();

        if (n1 > n2) {
            return 1;
        }
        if (n1 < n2) {
            return -1;
        }
        return 0;
    };

    const onFilterChange = (filters: FilterData[]) => {
        setFilters(filters);
    };

    // filter side effect
    useEffect(() => {
        if (filters.length === 0 && searchQuery.length === 0) {
            //default filters = show leadership
            setTeam(
                employeeList
                    .filter((member) => member.leadership)
                    .sort((emp1: TeamMemberData, emp2: TeamMemberData) => {
                        return emp1.order < emp2.order ? -1 : 1;
                    })
            );
            return;
        }

        const filteredTeam = employeeList.filter((member) => {
            let match = true;
            filters.forEach((filter) => {
                if (filter.category === "Locations") {
                    if (member.location !== filter.value) {
                        match = false;
                    }
                }
                if (filter.category === "Services") {
                    if (!member.services?.includes(filter.value)) {
                        match = false;
                    }
                }
            });
            return match;
        });

        if (searchQuery.length > 0) {
            const filteredBySearch = filteredTeam.filter((member) => {
                return member.name
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase());
            });

            setTeam(
                filteredBySearch.sort(
                    (emp1: TeamMemberData, emp2: TeamMemberData) => {
                        return emp1.order < emp2.order ? -1 : 1;
                    }
                )
            );
            return;
        }

        setTeam(
            filteredTeam.sort((emp1: TeamMemberData, emp2: TeamMemberData) => {
                return emp1.order < emp2.order ? -1 : 1;
            })
        );
    }, [filters, searchQuery, employeeList]);

    useEffect(() => {
        if (employeesData) {
            let tempLocations: string[] = [];
            let tempServices: string[] = [];

            const formatted = employeesData.employees?.nodes?.map(
                (employee: any) => {
                    const details: EmployeeDetails = employee.employeeDetails;
                    const employeeServices =
                        details.services?.nodes?.map((service: any) => {
                            tempServices.push(service?.name);
                            return service?.name;
                        }) || [];
                    const employeeLocations =
                        details.location?.nodes?.map<string>(
                            (location: any) => {
                                tempLocations.push(location?.title);
                                return location?.title;
                            }
                        ) || [];

                    return {
                        name: employee.title as string,
                        order: employee.menuOrder || 0,
                        title: details.title,
                        leadership: details.leadership || false,
                        location: employeeLocations?.join(),
                        services: employeeServices,
                        email: details.email,
                        image: {
                            url: details.headshot?.node?.mediaItemUrl,
                            altText: details.headshot?.node?.altText,
                        },
                    } as TeamMemberData;
                }
            );

            setServices(Array.from(new Set(tempServices)));
            setLocations(Array.from(new Set(tempLocations)));

            setEmployeeList(formatted || []);
        }
    }, [employeesData]);

    // useEffect(() => {
    //     setFilters([
    //         {
    //             category: "Services",
    //             value: "Leadership",
    //         },
    //     ]);
    // }, []);

    return (
        <section>
            <div className="container mx-auto">
                <header className="mb-6">
                    <h1>{data?.header}</h1>
                </header>
                <div className="filters mb-12">
                    <header className="mb-4 pb-2">
                        <div className="search-field">
                            {searchQuery.length === 0 && (
                                <button>
                                    <img
                                        className="search-icon"
                                        src={searchIcon}
                                        alt="search icon"
                                    />
                                </button>
                            )}
                            {searchQuery.length > 0 && (
                                <button
                                    className="clear-search"
                                    onClick={() => {
                                        setSearchQuery("");
                                    }}
                                ></button>
                            )}
                            <input
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                type="text"
                                className="search-input serif"
                                placeholder="Search an expert by name"
                            />
                        </div>
                    </header>

                    <TeamFilters
                        filters={[
                            {
                                category: "Services",
                                values: services,
                            },
                            {
                                category: "Locations",
                                values: locations,
                            },
                        ]}
                        onFilterChange={onFilterChange}
                    />
                </div>

                <div className="team">
                    <header className="mb-8">
                        {/* {filters.length === 0 && searchQuery.length === 0 && (
                            <motion.h2
                                animate={{ opacity: 1 }}
                                initial={{ opacity: 0 }}
                                exit={{ opacity: 0 }}
                            >
                                <i>Leadership Team</i>
                            </motion.h2>
                        )} */}
                    </header>
                    <ul className="team-list bc-border-list">
                        {loading && (
                            <li className="realtive py-4">
                                <p>Loading...</p>
                            </li>
                        )}
                        {!loading && team.length === 0 && (
                            <li className="realtive py-4">
                                <p>
                                    No team members match the selected filter.
                                </p>
                            </li>
                        )}
                        {team.map((member, index) => (
                            <li
                                className="team-member realtive py-4 hover:bg-bc-main-neutral"
                                key={`team-member-${index}`}
                            >
                                <div className="team-member-info grid grid-cols-12 gap-4 lg:gap-6 items-center">
                                    <div className="col-span-12 md:col-span-12 lg:col-span-4 pl-2">
                                        <p className="font-medium">
                                            {member.name}
                                        </p>
                                    </div>
                                    <div className="col-span-6 md:col-span-5 lg:col-span-4 pl-2">
                                        <p>{member.title}</p>
                                    </div>
                                    <div className="col-span-6 md:col-span-5 lg:col-span-3">
                                        <p>{member.location}</p>
                                    </div>
                                    <div className="col-span-12 md:col-span-2 lg:col-span-1 relative flex justify-end">
                                        <Link
                                            className="mail-link hover:text-bc-bright-blue"
                                            to={`mailto:${member.email}`}
                                            target="_blank"
                                        >
                                            <svg
                                                className="inline"
                                                width="40"
                                                height="40"
                                                viewBox="0 0 40 40"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    className="envelope-flap"
                                                    d="M5.00377 6.5L14.5718 16.0672C15.3601 16.8554 16.6408 16.847 17.4186 16.0484L26.719 6.5"
                                                    stroke="#2885FF"
                                                    strokeWidth="3"
                                                />
                                                <path
                                                    className="envelope-crease"
                                                    d="M5.00377 6.5L14.5718 16.0672C15.3601 16.8554 16.6408 16.847 17.4186 16.0484L26.719 6.5"
                                                    stroke="#2885FF"
                                                    strokeWidth="3"
                                                />
                                                <rect
                                                    x="2"
                                                    y="9"
                                                    width="28"
                                                    height="19"
                                                    rx="2"
                                                    stroke="#2885FF"
                                                    strokeWidth="3"
                                                />
                                            </svg>
                                        </Link>

                                        {member?.image?.url && (
                                            <div
                                                className="team-member-image-frame"
                                                title={member.name}
                                                style={{
                                                    backgroundImage: `url(${member.image.url})`,
                                                }}
                                            ></div>
                                        )}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Team;
