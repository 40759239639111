import { useState, useEffect } from "react";
import parse from "html-react-parser";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import BasePage from "./Base";
import Section from "../Components/Section/Section";
import Accordion from "../Components/Accordion/Accordion";
import { Colors } from "../Types/Global";
import { useGetHomepageDataQuery } from "../generated/graphql";
import TwoColumnTextBlock from "../Components/TwoColumnText/TwoColumnText";
import HomePageBanner from "../Components/Banners/HomepageBanner";
import MeetUs from "../Components/MeetUs/MeetUs";
import HomepageUpperCards from "../Components/HomepageUpperCards/HomepageUpperCards";
import HomepageCardsLower from "../Components/HomepageCardsLower/HomepageCardsLower";
import Loading from "../Components/Loading/Loading";
import DataUtilities from "../Data/Utilities";

const Home = () => {
    const { data, loading, error } = useGetHomepageDataQuery();

    const [pageData, setPageData] = useState<any>(null);
    const [seoData, setSeoData] = useState<{
        fullHead: string;
        title: string;
    } | null>(null);

    useEffect(() => {
        if (data && !error) {
            const blocks = (data?.nodeByUri as any)?.editorBlocks;
            setPageData(DataUtilities.flattenEditorBlocks(blocks));
            setSeoData((data?.nodeByUri as any)?.seo);
        }
    }, [data, error]);

    const upperCardsData = pageData?.find(
        (block: any) => block.name === "acf/homepage-upper-cards"
    );

    const bannerData = pageData?.find(
        (block: any) => block.name === "acf/homepage-banner"
    );

    const accordionData = pageData?.find(
        (block: any) => block.name === "acf/services-accordion"
    );

    const twoColumnTextData = pageData?.find(
        (block: any) => block.name === "acf/two-column-text"
    );

    const lowerCardsData = pageData?.find(
        (block: any) => block.name === "acf/homepage-cards-lower"
    );

    const homepageCtaData = pageData?.find(
        (block: any) => block.name === "acf/homepage-cta"
    );

    if (loading) {
        return <Loading />;
    }

    if (error) {
        // show error?
        console.log("query error!");
    }

    return (
        <BasePage>
            <Helmet>
                <title>{seoData?.title}</title>
                {parse(seoData?.fullHead || "")}
            </Helmet>
            <motion.div
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 10, opacity: 0 }}
            >
                {bannerData && <HomePageBanner {...bannerData} />}

                <Section gutter={false}>
                    {upperCardsData && (
                        <HomepageUpperCards {...upperCardsData} />
                    )}
                </Section>

                <Section backgroundColor={Colors["main-neutral"]}>
                    {twoColumnTextData && (
                        <TwoColumnTextBlock {...twoColumnTextData} />
                    )}
                    <div className="container mx-auto py-2">
                        {accordionData && <Accordion {...accordionData} />}
                    </div>
                </Section>

                <Section>
                    {lowerCardsData && (
                        <HomepageCardsLower {...lowerCardsData} />
                    )}
                </Section>

                <Section>
                    {homepageCtaData && <MeetUs {...homepageCtaData} />}
                </Section>
            </motion.div>
        </BasePage>
    );
};

export default Home;
