import { AcfImage } from "../../Data/dtos";
import Button from "../Buttons/Button";
import FreshIcon from "../../assets/icons/fresh.svg";
import ArrowIcon from "../Arrow/Arrow";
import "./styles.scss";
import { Link } from "react-router-dom";

interface NewsBannerProps {
    image?: AcfImage;
    isSinglePost?: boolean;
    title?: string;
    categoryText?: string;
    excerpt?: string;
    uri?: string;
    fresh?: boolean;
}

const NewsBanner = (props: NewsBannerProps) => {
    const {
        image,
        title,
        categoryText,
        excerpt,
        uri,
        fresh,
        isSinglePost = false,
    } = props;

    if (isSinglePost) {
        return (
            <div className="news-banner bg-bc-light-blue relative pt-14 pb-8">
                <div className="container mx-auto py-12 relative">
                    <header className="mb-12">
                        <Link
                            to="/news"
                            className="text-bc-soft-black flex gap-4"
                        >
                            <ArrowIcon flipped={true} width={"1rem"} /> Back to
                            all articles
                        </Link>
                        <h1 className="mt-8">{title}</h1>
                    </header>
                    {image?.url && (
                        <div className="featured-post grid grid-cols-12 grid-rows-auto rounded-lg overflow-hidden">
                            <div className="featured-post-img col-span-12 relative">
                                <div
                                    className="img-frame"
                                    style={{
                                        backgroundImage: `url(${image?.url})`,
                                    }}
                                >
                                    {fresh && (
                                        <div className="fresh-tag">
                                            <img
                                                src={FreshIcon}
                                                alt="fresh post"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="news-banner bg-bc-light-blue relative">
            <div className="container mx-auto py-12 relative">
                <header className="mb-12">
                    <h1 className="">News from The Barcode Group</h1>
                </header>
                <div className="featured-post grid grid-cols-12 grid-rows-auto rounded-lg overflow-hidden">
                    <div className="featured-post-img col-span-12 lg:col-span-6 relative">
                        <div
                            className="img-frame"
                            style={{ backgroundImage: `url(${image?.url})` }}
                        >
                            {fresh && (
                                <div className="fresh-tag">
                                    <img src={FreshIcon} alt="fresh post" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="featured-post-content col-span-12 lg:col-span-6 bg-bc-main-neutral px-8 py-12 flex flex-col justify-between">
                        <header>
                            <p>{categoryText}</p>
                            <h2 className="serif mt-2">{title}</h2>
                            {excerpt && (
                                <div
                                    className="mt-4"
                                    dangerouslySetInnerHTML={{
                                        __html: excerpt,
                                    }}
                                ></div>
                            )}
                        </header>

                        <div className="mt-12">
                            {uri && (
                                <Button to={uri} text="Read on!" size="lg" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsBanner;
