import { useState, useEffect } from "react";
import parse from "html-react-parser";
import { Helmet } from "react-helmet-async";
import BasePage from "./Base";
import Contact from "../Components/Contact/Contact";
import Locations from "../Components/Locations/Locations";
import Team from "../Components/Team/Team";
import Join from "../Components/Join/Join";
import Spacer from "../Components/Spacer/Spacer";
import Section from "../Components/Section/Section";
import GetAccess from "../Components/GetAccess/GetAccess";
import NewsletterSignup from "../Components/NewsletterSignup/NewsletterSignup";
import useGetCompanyLocationsQuery from "../Data/UseGetLocationsQuery";
import { useCompanyDetailsContext } from "../Providers/CompanyDetails";
import PromotedTeamMembers from "../Components/PromotedTeamMembers/PromotedTeamMembers";
import Loading from "../Components/Loading/Loading";
import { useGetConnectPageDataQuery } from "../generated/graphql";
import DataUtilities from "../Data/Utilities";

const Connect = () => {
    const { data, loading, error } = useGetConnectPageDataQuery();
    const { companyContacts, loading: companyDetailsLoading } =
        useCompanyDetailsContext();
    const {
        data: locationItemsData,
        loading: locationLoading,
        error: locationsError,
    } = useGetCompanyLocationsQuery();

    const [pageData, setPageData] = useState<any>(null);
    const [seoData, setSeoData] = useState<{
        fullHead: string;
        title: string;
    } | null>(null);

    useEffect(() => {
        if (data && !error) {
            const blocks = (data?.nodeByUri as any)?.editorBlocks;
            setPageData(DataUtilities.flattenEditorBlocks(blocks));
            setSeoData((data?.nodeByUri as any)?.seo);
        }
    }, [data, error]);

    useEffect(() => {
        if (!loading && !companyDetailsLoading && !locationLoading) {
            window.setTimeout(() => {
                let currentLocation = window.location.href;
                const hasAnchor = currentLocation.includes("#");
                if (hasAnchor) {
                    console.log("has anchor");
                    const anchorId = `${currentLocation.substring(
                        currentLocation.indexOf("#") + 1
                    )}`;
                    const anchorElement = document.getElementById(anchorId);
                    if (anchorElement) {
                        anchorElement.scrollIntoView({ behavior: "smooth" });
                    }
                }
            }, 500);
        }
    }, [loading, companyDetailsLoading, locationLoading]);

    const locationsData = pageData?.find(
        (block: any) => block.name === "acf/connect-locations"
    );

    const contactsData = pageData?.find(
        (block: any) => block.name === "acf/connect-contacts"
    );

    const teamData = pageData?.find(
        (block: any) => block.name === "acf/connect-employees"
    );

    const JoinData = pageData?.find(
        (block: any) => block.name === "acf/connect-cta"
    );

    const promotedTeamMembersData = pageData?.find(
        (block: any) => block.name === "acf/promoted-team-members"
    );

    const aboutCtaData = pageData?.find(
        (block: any) => block.name === "acf/about-cta"
    );

    if (loading || companyDetailsLoading || locationLoading) {
        return <Loading />;
    }

    return (
        <BasePage>
            <Helmet>
                <title>{seoData?.title}</title>
                {parse(seoData?.fullHead || "")}
            </Helmet>
            <Spacer size="lg" />
            <PromotedTeamMembers {...promotedTeamMembersData} />
            {/* <Contact contacts={companyContacts} data={contactsData} /> */}
            <Spacer height={100} />
            <Locations locations={locationItemsData} data={locationsData} />
            <Spacer height={100} />
            <Team data={teamData} />
            <Spacer height={100} />
            <Join {...JoinData} />

            <Spacer size="sm" />

            {/* Insight downloads */}
            <Section>
                <div className="container mx-auto py-12 border border-bc-soft-black rounded-lg">
                    <div className="flex flex-col md:flex-row justify-between gap-8 items-center">
                        <div className="w-full md:w-1/3 p-4 lg:p-8">
                            <h3 className="text-bc-salmon">
                                {aboutCtaData?.header}
                            </h3>
                        </div>
                        <div className="w-full md:w-1/3">
                            <GetAccess
                                title={aboutCtaData?.reports?.header}
                                superTitle={aboutCtaData?.reports?.superTitle}
                                copy={aboutCtaData?.reports?.content}
                                buttonText={aboutCtaData?.reports?.accessText}
                            />
                        </div>
                        <div className="w-full md:w-1/3">
                            <NewsletterSignup />
                        </div>
                    </div>
                </div>
            </Section>

            <Spacer size="md" />
        </BasePage>
    );
};

export default Connect;
