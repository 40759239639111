import { useState, useEffect } from "react";
import NewsletterSignup from "../NewsletterSignup/NewsletterSignup";
import Modal from "./Modal";
import "./styles.scss";

interface NewsletterModalProps {
    open: boolean;
    onClose: () => void;
}

const NewsletterModal = ({ open, onClose }: NewsletterModalProps) => {
    return (
        <Modal open={open} onClose={onClose} border={true}>
            <NewsletterSignup
                onSuccess={() => {
                    onClose();
                }}
            />
        </Modal>
    );
};

export default NewsletterModal;
