import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import BasePage from "./Base";
import downloadCTAImage from "../assets/images/cta-dl-news.jpg";
import { useGetNewsQuery } from "../generated/graphql";
import Loading from "../Components/Loading/Loading";
import NewsBanner from "../Components/NewsBanner/NewsBanner";
import Button from "../Components/Buttons/Button";
import DropDownButton from "../Components/Buttons/DropdownButton";
import { useModals, Modal } from "../Providers/Modal";
import NewsPostPreview from "../Components/NewsPostPreview/NewsPostPreview";
import InsightsStaticImage from "../assets/static/insights-static.jpg";
import DataUtilities from "../Data/Utilities";
import { NewsPost } from "../Data/dtos";

const News = () => {
    const { data, loading, error } = useGetNewsQuery();
    const [featuredPost, setFeaturedPost] = useState<NewsPost | null>(null);
    const [recentPosts, setRecentPosts] = useState<NewsPost[]>([]);
    const [archivedPosts, setArchivedPosts] = useState<NewsPost[]>([]);
    const Modals = useModals();

    useEffect(() => {
        if (data && !error) {
            //flatten all post data
            const posts = data.posts?.nodes
                .map(DataUtilities.flattenNewsPostData)
                .sort((a, b) => b.date.getTime() - a.date.getTime());

            //split the posts into segments
            const featuredPost = posts?.find((post) => post.promoted);

            setFeaturedPost(featuredPost || null);

            const recentPosts = posts
                ?.filter((post) => post.id !== featuredPost?.id)
                .slice(0, 6);

            setRecentPosts(recentPosts || []);

            if (posts?.length && posts?.length > 7) {
                // 1 featured post and 6 recents the rest are archived
                const archPosts = posts?.filter(
                    (post) =>
                        post.id !== featuredPost?.id &&
                        !recentPosts?.find((recent) => recent.id === post.id)
                );

                setArchivedPosts(archPosts || []);
            }
        }
    }, [data, error]);

    if (loading) {
        return <Loading />;
    }

    return (
        <BasePage>
            <Helmet>
                <title>News - The Barcode Group</title>
            </Helmet>
            <NewsBanner
                image={featuredPost?.featuredImage}
                title={featuredPost?.title}
                excerpt={featuredPost?.excerpt}
                categoryText={featuredPost?.categories?.join(", ")}
                uri={featuredPost?.uri}
                fresh={featuredPost?.fresh}
            />

            <div className="news-recent container mx-auto my-8">
                {/* recent posts that aren't the first promoted one / featured */}
                <ul className="grid grid-cols-12 grid-rows-auto gap-8">
                    {recentPosts?.map((post) => (
                        <NewsPostPreview
                            key={`recent-post-${post.id}`}
                            {...post}
                        />
                    ))}
                </ul>
            </div>

            <footer className="news-footer mb-12 pt-12">
                <div className="container mx-auto">
                    <div className="grid grid-cols-12 grid-rows-auto gap-8">
                        <div className="archived-posts col-span-12 lg:col-span-8 bg-bc-light-blue rounded-lg px-8 py-8">
                            <h2>More Posts</h2>
                            <ul className="bc-border-list mt-8 mb-8">
                                {archivedPosts?.length > 0 ? (
                                    archivedPosts?.map((post, index) => (
                                        <li
                                            className="py-4 flex justify-between items-center gap-4"
                                            key={`archived-post-${post.id}`}
                                        >
                                            <span>
                                                {post?.categories?.join(", ")}
                                            </span>
                                            <div className="grow overflow-hidden pr-2">
                                                <Button
                                                    className="w-full"
                                                    to={post?.uri}
                                                    arrowStrokeClass="stroke-bc-soft-black"
                                                    clip={true}
                                                    text={post?.title}
                                                    size="sm"
                                                />
                                            </div>
                                        </li>
                                    ))
                                ) : (
                                    <li>
                                        <p>No older posts found.</p>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className="col-span-12 lg:col-span-4 rounded-lg border-2 border-bc-salmon">
                            <div className="p-4 grid grid-cols-12 gap-0 md:gap-12 lg:gap-0">
                                <div
                                    className="col-span-12 md:col-span-6 lg:col-span-12 relative"
                                    style={{ minHeight: "24rem" }}
                                >
                                    <div
                                        className="bleed-image rounded-lg"
                                        style={{
                                            backgroundImage: `url(${downloadCTAImage})`,
                                        }}
                                    ></div>
                                </div>
                                <div className="col-span-12 md:col-span-6 lg:col-span-12">
                                    <div className="flex flex-col gap-4 mt-8">
                                        <h4>Reports for you to have.</h4>
                                        <h2 className="serif text-bc-salmon">
                                            Download our Insights.
                                        </h2>
                                        <p>
                                            We're constantly gaining industry
                                            knowledge: from tips and tricks to
                                            back-end data, we've got crazy
                                            amounts of knowledge ready to share
                                            with you.
                                        </p>
                                    </div>

                                    <footer className="mt-4 pb-8">
                                        <DropDownButton
                                            text="Get access"
                                            onClick={() => {
                                                Modals.openModal(
                                                    Modal.DOWNLOADS
                                                );
                                            }}
                                        />
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </BasePage>
    );
};

export default News;
