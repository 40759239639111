import React, { useEffect, useState, useRef } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import Button from "../Buttons/Button";
import { AcfServicesAccordion } from "../../Data/dtos";
import AccordionItem from "./AccordionItem";
import DataUtilities from "../../Data/Utilities";
import "./style.scss";

const Accordion = (props: AcfServicesAccordion) => {
    const { accordion, bottomText } = props;
    const { width: screenWidth, height: screenHeight } = useWindowSize();
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeWidth, setActiveWidth] = useState(0);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const activeIndexRef = useRef(activeIndex);

    const itemRefs = useRef(
        accordion?.map(() => React.createRef<HTMLLIElement>())
    );

    const updateActiveItemDimensions = () => {
        // Check if the active item's ref is current and not null
        const activeItemRef = itemRefs.current?.[activeIndexRef.current];
        if (activeItemRef && activeItemRef.current && screenWidth) {
            const width = activeItemRef.current.getBoundingClientRect().width;

            setActiveWidth(width);

            if (screenWidth <= 980) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false);
            }
        }
    };

    useEffect(() => {
        updateActiveItemDimensions();
    }, [screenWidth, screenHeight]);

    useEffect(() => {
        activeIndexRef.current = activeIndex;
    }, [activeIndex]);

    if (!accordion) return null;

    return (
        <>
            <div className="bc-accordion">
                <ul>
                    {accordion.map((item, index) => {
                        return (
                            <AccordionItem
                                key={`accordion-item-${index}`}
                                ref={itemRefs.current?.[index]}
                                index={index}
                                activeIndex={activeIndex}
                                activeWidth={activeWidth}
                                mobileLayout={isSmallScreen}
                                {...item}
                                onClick={(index) => {
                                    setActiveIndex(index);
                                }}
                            />
                        );
                    })}
                </ul>
            </div>

            <div className="grid grid-cols-12 grid-rows-1 gap-8">
                <div className="hidden md:block col-span-1 lg:col-span-5">
                    &nbsp;
                </div>
                <div className="col-span-11 lg:col-span-7 pb-4">
                    <div className="pt-8 pb-12">
                        <h3 className="mb-6">{bottomText?.header}</h3>

                        <div className="flex gap-8 flex-wrap">
                            {bottomText.links.map((link: any, index) => {
                                return (
                                    <React.Fragment
                                        key={`accordion-link-${index}`}
                                    >
                                        <Button
                                            to={DataUtilities.localizeUrl(
                                                link?.url
                                            )}
                                            text={link?.title}
                                            size="sm"
                                        />
                                        {index < bottomText.links.length - 1 &&
                                            bottomText?.linkSeparator && (
                                                <p className="w-full sm:w-auto">
                                                    {bottomText?.linkSeparator}
                                                </p>
                                            )}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Accordion;
