import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import SmallLogo from "../../assets/images/small-logo.svg";
import "./styles.scss";

const GlobalNavigation = () => {
    const { pathname } = useLocation();
    const [open, setOpen] = useState(false);
    const [canTrigger, setCanTrigger] = useState(true);
    const [triggerTimer, setTriggerTimer] = useState<number | null>(null);
    const [timer, setTimer] = useState<number | null>(null);

    useEffect(() => {
        setOpen(false);
    }, [pathname]);

    useEffect(() => {
        if (!open) {
            if (triggerTimer) window.clearTimeout(triggerTimer);
            setTriggerTimer(
                window.setTimeout(() => {
                    setCanTrigger(true);
                }, 1000)
            );
        }
    }, [open]);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleMouseLeave = () => {
        if (timer) {
            clearTimeout(timer);
        }

        setTimer(
            window.setTimeout(() => {
                setOpen(false);
            }, 500)
        );
    };

    const handleMouseEnter = () => {
        if (timer) {
            clearTimeout(timer);
        }
    };

    return (
        <div className="global-menu-container">
            <button
                className={`global-nav-btn ${
                    open ? "active bg-bc-bright-blue" : "bg-bc-soft-black"
                }`}
                onClick={handleClick}
                onMouseEnter={() => {
                    if (canTrigger) {
                        setCanTrigger(false);
                        setOpen(true);
                    }
                }}
            >
                {/* <img className="m-auto" src={barcode} alt="barcode" /> */}
                <div className="barcode">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </button>
            <nav
                className={`bc-nav-menu bg-bc-bright-blue text-right px-4 ${
                    open ? " open" : ""
                }`}
                onMouseLeave={() => handleMouseLeave()}
                onMouseEnter={() => handleMouseEnter()}
            >
                <ul className="menu-items">
                    <li>
                        <Link
                            to={`/`}
                            className={pathname === "/" ? "active" : ""}
                        >
                            <span>Home</span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={`/services`}
                            className={pathname === "/services" ? "active" : ""}
                        >
                            <span>Services</span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={`/culture`}
                            className={pathname === "/culture" ? "active" : ""}
                        >
                            <span>Culture</span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={`/connect`}
                            className={pathname === "/connect" ? "active" : ""}
                        >
                            <span>Connect</span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={`/news`}
                            className={pathname === "/news" ? "active" : ""}
                        >
                            <span>News</span>
                        </Link>
                    </li>
                </ul>

                <footer>
                    <a
                        rel="noreferrer"
                        className="social-link text-bc-soft-white"
                        href="https://www.linkedin.com/company/the-barcode-group/"
                        target="_blank"
                    >
                        <span>LinkedIn</span>
                    </a>

                    <div className="w-full text-center">
                        <Link to={`/`} className="nav-logo">
                            <img
                                className="small-logo mx-auto"
                                src={SmallLogo}
                                alt="small logo"
                            />
                        </Link>
                    </div>
                </footer>
            </nav>
        </div>
    );
};

export default GlobalNavigation;
