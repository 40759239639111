import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import BasePage from "./Base";
import { Colors } from "../Types/Global";
import Spacer from "../Components/Spacer/Spacer";
import Section from "../Components/Section/Section";
import Banner from "../Components/Banners/Banner";
import TwoColumnTextBlock from "../Components/TwoColumnText/TwoColumnText";
import VerticalAccordionList from "../Components/VerticalAccordionList/VerticalAccordionList";
import ServicesImageGrid from "../Components/ServicesImageGrid/ServicesImageGrid";
import AgencyStats from "../Components/AgencyStats/AgencyStats";
import Loading from "../Components/Loading/Loading";
import ImageMarquee from "../Components/ImageMarquee/ImageMarquee";
import ServiceColumns from "../Components/ServiceColumns/ServiceColumns";
import LegacyCta from "../Components/LegacyCta/LegacyCta";
import { useCompanyDetailsContext } from "../Providers/CompanyDetails";
import { AcfVerticalAccordion, AcfJoinTeam } from "../Data/dtos";
import { useGetServicesPageDataQuery } from "../generated/graphql";
import GetInTouch from "../Components/GetInTouch/GetInTouch";
import DataUtilities from "../Data/Utilities";

const Services = () => {
    const { data, loading, error } = useGetServicesPageDataQuery();
    const { companyContacts, loading: companyDetailsLoading } =
        useCompanyDetailsContext();

    const [pageData, setPageData] = useState<any>(null);
    const [seoData, setSeoData] = useState<{
        fullHead: string;
        title: string;
    } | null>(null);

    useEffect(() => {
        if (data && !error) {
            const blocks = (data?.nodeByUri as any)?.editorBlocks;
            setPageData(DataUtilities.flattenEditorBlocks(blocks));
            setSeoData((data?.nodeByUri as any).seo);
        }
    }, [data, error]);

    useEffect(() => {
        if (!loading && !companyDetailsLoading) {
            window.setTimeout(() => {
                let currentLocation = window.location.href;
                const hasAnchor = currentLocation.includes("#");
                if (hasAnchor) {
                    const anchorId = `${currentLocation.substring(
                        currentLocation.indexOf("#") + 1
                    )}`;
                    const anchorElement = document.getElementById(anchorId);
                    if (anchorElement) {
                        anchorElement.scrollIntoView({ behavior: "smooth" });
                    }
                }
            }, 500);
        }
    }, [loading, companyDetailsLoading]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        //show error
        console.log("query error", error);
    }

    const bannerData = pageData?.find(
        (block: any) => block.name === "acf/banner"
    );

    const twoColumnTextData = pageData?.find(
        (block: any) => block.name === "acf/two-column-text"
    );

    const verticalAccordions = pageData?.filter(
        (block: any) => block.name === "acf/vertical-accordion"
    ) as AcfVerticalAccordion[];

    const statsBannerData = pageData?.find(
        (block: any) => block.name === "acf/stats-banner"
    );

    const logoTickerImageData = pageData?.find(
        (block: any) => block.name === "acf/logo-ticker"
    );

    const serviceCardsData = pageData?.find(
        (block: any) => block.name === "acf/service-cards"
    );

    const serviceColumnData = pageData?.find(
        (block: any) => block.name === "acf/service-columns"
    );

    const serviceCtaData = pageData?.find(
        (block: any) => block.name === "acf/service-cta"
    );

    const joinOurTeamData: AcfJoinTeam = pageData?.find(
        (block: any) => block.name === "acf/join-team"
    );

    console.log(joinOurTeamData);

    return (
        <BasePage>
            <Helmet>
                <title>{seoData?.title}</title>
                {parse(seoData?.fullHead || "")}
            </Helmet>

            <Banner {...bannerData} />
            <Section>
                <TwoColumnTextBlock {...twoColumnTextData} />
            </Section>

            {/* Two column accordion thingy */}
            <Section>
                <div className="container mx-auto py-0">
                    <div className="border-0 rounded-lg flex gap-4 flex-wrap lg:flex-nowrap">
                        {verticalAccordions?.map((accordion, index) => (
                            <div
                                className="w-full lg:w-1/2 border border-bc-soft-black rounded-lg p-8"
                                key={`vertical-accordion-${index}`}
                            >
                                <VerticalAccordionList {...accordion} />
                            </div>
                        ))}
                    </div>
                </div>
            </Section>

            <Section>
                <ServicesImageGrid {...serviceCardsData} />
            </Section>

            <Spacer size="sm" />

            <Section>
                <AgencyStats {...statsBannerData} />
            </Section>

            <Section>
                <div className="bc-marquee my-12" id="partners">
                    <div className="px-8 mx-auto">
                        <div className="bc-marguee-content border-b border-t border-bc-soft-black py-6">
                            <ImageMarquee
                                images={logoTickerImageData?.logos || []}
                            />
                        </div>
                    </div>
                </div>
            </Section>

            {/* Brand Solutions */}
            <Section backgroundColor={Colors["main-neutral"]}>
                <Spacer size="md" />
                <ServiceColumns {...serviceColumnData} />
            </Section>

            <Spacer size="sm" />

            {/* Get in Touch */}

            {joinOurTeamData && <GetInTouch {...joinOurTeamData} />}

            {/* <Section>
                <div className="container mx-auto">
                    <div className="flex gap-8 justify-between flex-wrap">
                        <div className="w-full sm:w-1/2">
                            <h3 className="mb-4">{serviceCtaData?.header}</h3>
                            <div
                                className="mb-2"
                                dangerouslySetInnerHTML={{
                                    __html: serviceCtaData?.content,
                                }}
                            ></div>
                            <Spacer height={100} />
                            {!companyDetailsLoading && (
                                <div className="flex flex-col lg:flex-row gap-8 service-contacts">
                                    <div className="w-full md:w-1/2">
                                        <header className="border-b border-bc-soft-black pb-4 mb-4">
                                            <p>Retail + Digital Sales</p>
                                        </header>

                                        <h4>
                                            {companyContacts?.newBusiness?.name}
                                        </h4>
                                        <Link
                                            to={`mailto:${companyContacts?.newBusiness?.email}`}
                                            className="text-sm contact-link"
                                        >
                                            <span>
                                                {
                                                    companyContacts?.newBusiness
                                                        ?.email
                                                }
                                            </span>
                                        </Link>
                                    </div>
                                    <div className="w-full md:w-1/2">
                                        <header className="border-b border-bc-soft-black pb-4 mb-4">
                                            <p>Project Services</p>
                                        </header>

                                        <h4>
                                            {
                                                companyContacts?.mediaAndPress
                                                    ?.name
                                            }
                                        </h4>
                                        <Link
                                            to={`mailto:${companyContacts?.mediaAndPress?.email}`}
                                            className="text-sm contact-link"
                                        >
                                            <span>
                                                {
                                                    companyContacts
                                                        ?.mediaAndPress?.email
                                                }
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="w-full sm:w-1/3">
                            <LegacyCta
                                contactSuper={serviceCtaData?.contactSuper}
                                contactHeader={serviceCtaData?.contactHeader}
                                link={serviceCtaData?.link}
                                image={serviceCtaData?.image}
                            />
                        </div>
                    </div>
                </div>
            </Section> */}
            <Spacer size="md" />
        </BasePage>
    );
};

export default Services;
