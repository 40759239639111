import { HTMLProps } from "react";

interface TeamFilterBtnProps
    extends Omit<HTMLProps<HTMLButtonElement>, "type"> {
    name: string;
    active: boolean;
}
const TeamFilterBtn = ({ name, active, ...props }: TeamFilterBtnProps) => {
    return (
        <button
            type="button"
            className={`team-filter-btn border border-bc-transparent relative ${
                active ? "active border border-bc-soft-black" : ""
            } py-1 pr-2 pl-2 rounded-lg hover:border-bc-bright-blue hover:border`}
            {...props}
        >
            {name}
            <span></span>
        </button>
    );
};

export default TeamFilterBtn;
