import { Link } from "react-router-dom";
import { AcfImage } from "../../Data/dtos";
import ArrowIcon from "../Arrow/Arrow";
import "./styles.scss";

interface DownloadButtonProps {
    file?: AcfImage;
    buttonCode?: string;
    text: string;
}

const DownloadButton = ({ file, buttonCode, text }: DownloadButtonProps) => {
    if (buttonCode) {
        return <div dangerouslySetInnerHTML={{ __html: buttonCode }}></div>;
    }

    if (file) {
        return (
            <Link
                to={file?.url}
                target="_blank"
                className="download-btn text-white bg-bc-salmon rounded-lg w-full flex justify-between items-center px-6 py-4"
            >
                <p>{text}</p> <ArrowIcon dark={true} width={"1.5rem"} />
            </Link>
        );
    }

    return null;
};

export default DownloadButton;
