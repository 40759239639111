import { Routes, Route, Location } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
// views
import Home from "../Views/Home";
import Services from "../Views/Services";
import About from "../Views/About";
import Connect from "../Views/Connect";
import ShoppingMaps from "../Views/ShoppingMaps";
import Resources from "../Views/Resources";
import News from "../Views/News";
import NewsSingle from "../Views/NewsSingle";

interface RouterProps {
    location: Location;
}

const trackPageView = (location: Location) => {
    const _hsq = ((window as any)._hsq = (window as any)?._hsq || []);

    if (_hsq) {
        _hsq.push(["setPath", location.pathname]);
        _hsq.push(["trackPageView"]);
    }
};

const Router = ({ location }: RouterProps) => {
    trackPageView(location);

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<Services />} />
                <Route path="/about" element={<About />} />
                <Route path="/culture" element={<About />} />
                <Route path="/connect" element={<Connect />} />
                <Route path="/shopping-maps" element={<ShoppingMaps />} />
                <Route path="/resources" element={<Resources />} />
                <Route path="/news" element={<News />} />
                <Route path="/news/:slug" element={<NewsSingle />} />
            </Routes>
        </AnimatePresence>
    );
};

export default Router;
