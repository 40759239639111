import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import TeamFilterCategoryBtn from "./TeamFilterCategoryBtn";
import TeamFilterBtn from "./TeamFilterBtn";
import { FilterData, Filter } from "./Team";
import useMeasure from "react-use-measure";
import "./styles.scss";

interface TeamFiltersProps {
    filters: Filter[];
    onFilterChange: (filters: FilterData[]) => void;
}

const TeamFilters = ({ filters, onFilterChange }: TeamFiltersProps) => {
    const [currentFilters, setCurrentFilters] = useState<FilterData[]>([
        {
            category: "Services",
            value: "Leadership",
        },
    ]);
    const [openCategory, setOpenCategory] = useState<string | null>(null);
    const [ref, { height }] = useMeasure();

    useEffect(() => {
        onFilterChange(currentFilters);

        if (openCategory === null) {
            setOpenCategory("Services");
        }
    }, [currentFilters]);

    useEffect(() => {
        if (filters) {
            console.log("filters", filters);
            // setCurrentFilters([
            //     {
            //         category: "Services",
            //         value: "Leadership",
            //     },
            // ]);
        }
    }, [filters]);

    return (
        <div className="team-filters mb-12">
            <nav className="team-filter-nav flex gap-4">
                {filters?.map((filter, index) => (
                    <TeamFilterCategoryBtn
                        key={`category-filter-${index}`}
                        active={openCategory === filter.category}
                        category={filter.category}
                        onClick={() => {
                            if (openCategory === filter.category) {
                                setOpenCategory(null);
                                return;
                            }
                            setOpenCategory(filter.category);
                        }}
                    />
                ))}
                {currentFilters.length > 0 && (
                    <button
                        className="team-filter-remove-btn"
                        onClick={() => {
                            setCurrentFilters([]);
                        }}
                    >
                        Remove Filters <span></span>
                    </button>
                )}
            </nav>

            <motion.div
                animate={{ height: height || "auto" }}
                className="filter-lists"
            >
                <div ref={ref} className="py-1">
                    {filters?.map((filter, index) => {
                        if (openCategory === filter.category) {
                            return (
                                <div
                                    key={`filter-list-${filter.category}-${index}`}
                                    className={`team-filter-list filters-${filter.category} pt-6`}
                                >
                                    <motion.div
                                        animate={{ opacity: 1 }}
                                        initial={{ opacity: 0 }}
                                        exit={{ opacity: 0 }}
                                        className="flex gap-4 flex-wrap"
                                    >
                                        {filter.values.map((value, index) => (
                                            <TeamFilterBtn
                                                name={value}
                                                active={
                                                    currentFilters.find(
                                                        (elememt) =>
                                                            elememt.value ===
                                                            value
                                                    )
                                                        ? true
                                                        : false
                                                }
                                                key={`filter-${filter.category}-${index}`}
                                                onClick={() => {
                                                    if (
                                                        currentFilters.find(
                                                            (elememt) =>
                                                                elememt.value ===
                                                                value
                                                        )
                                                    ) {
                                                        setCurrentFilters([]);
                                                    } else {
                                                        setCurrentFilters([
                                                            {
                                                                category:
                                                                    filter.category,
                                                                value: value,
                                                            },
                                                        ]);
                                                    }
                                                }}
                                            >
                                                {value}
                                            </TeamFilterBtn>
                                        ))}
                                    </motion.div>
                                </div>
                            );
                        }
                    })}
                </div>
            </motion.div>
        </div>
    );
};

export default TeamFilters;
