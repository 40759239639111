import { AcfImage, AcfLink, AcfShoppingMapBanner } from "../../Data/dtos";
import DownloadButton from "../Buttons/DownloadButton";
import "./styles.scss";

interface SpecialBannerProps {
    title?: string;
    superTitle?: string;
    contentTitle?: string;
    content: string;
    image?: AcfImage;
    link?: AcfImage;
    buttonCode?: string;
}

const SpecialBanner = (props: SpecialBannerProps) => {
    const {
        image,
        content,
        title,
        contentTitle,
        superTitle,
        link,
        buttonCode,
    } = props;
    return (
        <div className="special-banner bg-bc-light-blue relative pt-8">
            <div className="container mx-auto py-12 relative">
                <header className="mb-12">
                    {title && <h1 className="">{title}</h1>}
                </header>
                <div className="grid grid-cols-12 grid-rows-auto rounded-lg overflow-hidden">
                    <div className="featured-img col-span-12 lg:col-span-6 relative">
                        <div
                            className="img-frame"
                            style={{ backgroundImage: `url(${image?.url})` }}
                        ></div>
                    </div>
                    <div className="banner-content col-span-12 lg:col-span-6 bg-bc-main-neutral px-8 py-12 flex justify-between items-center">
                        <div className="w-full">
                            {superTitle && <h4>{superTitle}</h4>}
                            {contentTitle && (
                                <h2 className="serif">{contentTitle}</h2>
                            )}
                            {content && (
                                <div
                                    className="flex flex-col gap-8 py-12 headline-serif"
                                    dangerouslySetInnerHTML={{
                                        __html: content,
                                    }}
                                ></div>
                            )}
                            {buttonCode && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: buttonCode,
                                    }}
                                ></div>
                            )}
                            {link && (
                                <DownloadButton text="Read on!" file={link} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpecialBanner;
