import "./styles.scss";

const Loading = () => {
    //0 0 50.71 51.42
    const y = 5;
    const height = 50;
    return (
        <div className="loading flex flex-col items-stretch bg-grey-lighter min-h-screen items-center justify-center">
            <div className="loader">
                <svg
                    id="Layer_2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 60"
                >
                    <g id="Layer_1-2">
                        <rect
                            className="barcode-bit"
                            x="7.38"
                            y={y}
                            width="1.93"
                            height={height}
                            fill="#000"
                            strokeWidth="0"
                        />
                        <rect
                            className="barcode-bit"
                            x="0"
                            y={y}
                            width="1.29"
                            height={height}
                            fill="#000"
                            strokeWidth="0"
                        />
                        <rect
                            className="barcode-bit"
                            x="15.57"
                            y={y}
                            width="2.8"
                            height={height}
                            fill="#000"
                            strokeWidth="0"
                        />
                        <rect
                            className="barcode-bit"
                            x="24.62"
                            y={y}
                            width="3.66"
                            height={height}
                            fill="#000"
                            strokeWidth="0"
                        />
                        <rect
                            className="barcode-bit"
                            x="34.54"
                            y={y}
                            width="4.53"
                            height={height}
                            fill="#000"
                            strokeWidth="0"
                        />
                        <rect
                            className="barcode-bit"
                            x="45.32"
                            y={y}
                            width="5.39"
                            height={height}
                            fill="#000"
                            strokeWidth="0"
                        />
                    </g>
                </svg>
            </div>
        </div>
    );
};

export default Loading;
