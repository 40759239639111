import { useState, useRef, useEffect } from "react";
import DownArrow from "../../assets/icons/down-arrow.svg";
import "./styles.scss";

interface VerticalAccordionItemProps {
    // key: string;
    label: string;
    copy: string;
}

const VerticalAccordionItem = (props: VerticalAccordionItemProps) => {
    const [openHeight, setOpenHeight] = useState(0);
    const [closedHeight, setClosedHeight] = useState<number | null>(null);
    const [open, setOpen] = useState(false);

    const copyBlockRef = useRef<HTMLDivElement>(null);
    const listItemRef = useRef<HTMLLIElement>(null);

    const getHeight = () => {
        if (open) {
            return openHeight;
        } else {
            if (!closedHeight) {
                return "auto";
            } else {
                return closedHeight;
            }
        }
    };

    const handleClick = (e: any) => {
        e.preventDefault();

        if (!open) {
            if (copyBlockRef.current) {
                const height =
                    copyBlockRef.current.getBoundingClientRect().height;
                setOpenHeight(height + closedHeight! + 20);

                setOpen(true);
            }
        } else {
            setOpen(false);
        }
    };

    // get height of copy block on mount
    useEffect(() => {
        if (listItemRef.current) {
            setClosedHeight(listItemRef.current.getBoundingClientRect().height);
        }
    }, []);

    return (
        <li
            ref={listItemRef}
            className={`bc-vertical-list-item relative overflow-hidden ${
                open ? "active" : ""
            }`}
            style={{ height: getHeight() }}
        >
            <button
                className="accordion-item-btn flex justify-between py-2 hover:text-bc-bright-blue"
                onClick={handleClick}
            >
                <p>{props.label}</p>
                <span className="flex-shrink-0">
                    <img
                        className="item-arrow"
                        src={DownArrow}
                        alt="down arrow"
                    />
                </span>
            </button>
            <div ref={copyBlockRef} className="absolute">
                <div
                    className="serif item-copy"
                    dangerouslySetInnerHTML={{ __html: props.copy! }}
                ></div>
            </div>
        </li>
    );
};

export default VerticalAccordionItem;
