import { AcfVerticalAccordion } from "../../Data/dtos";
import VerticalAccordionItem from "./VerticalAccordionItem";

const VerticalAccordionList = (props: AcfVerticalAccordion) => {
    const { accordion, header, sectionNumber } = props;

    return (
        <div className="card">
            {sectionNumber && (
                <p className="card-number mb-4">{sectionNumber}</p>
            )}
            <h1 className="mb-6">{header}</h1>
            <ul className="bc-border-list">
                {accordion?.map((item, index) => (
                    <VerticalAccordionItem
                        key={`list-item-${index}`}
                        label={item?.title!}
                        copy={item?.text!}
                    />
                ))}
            </ul>
        </div>
    );
};

export default VerticalAccordionList;
