import { useState, useRef, useEffect } from "react";
import { StatsBanner_Fields } from "../../generated/graphql";
import useOnScreen from "../../Hooks/IsVisible";
import AnimatedNumbers from "../AnimatedNumbers/AnimatedNumbers";
import { stat } from "fs";

const AgencyStats = (props: StatsBanner_Fields) => {
    const { content, stats } = props;
    const ref = useRef<HTMLDivElement>(null);
    const [playAnimation, setPlayAnimation] = useState(false);

    const isVisible = useOnScreen(ref, { threshold: 0.5 });

    useEffect(() => {
        if (playAnimation) return;

        if (isVisible) {
            setPlayAnimation(true);
        }
    }, [isVisible]);

    return (
        <div ref={ref} className="container mx-auto">
            <div className="grid grid-cols-12 gap-8">
                {stats?.map((statContent, index) => (
                    <div
                        className={`col-span-12 lg:col-span-${3 + index}`}
                        key={`stat-block-${index}`}
                    >
                        <h3 className="text-bc-bright-blue">
                            <AnimatedNumbers
                                endValue={statContent?.stat || 0}
                                start={playAnimation}
                            />{" "}
                            {statContent?.statSuffix}
                        </h3>
                    </div>
                ))}
                <div
                    className="col-span-12 lg:col-span-5"
                    dangerouslySetInnerHTML={{ __html: content! }}
                ></div>
            </div>
        </div>
    );
};

export default AgencyStats;
