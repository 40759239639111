import { useEffect, useMemo, useState, RefObject, useRef } from "react";

interface UseOnScreenOptions {
    threshold?: number; // the distance an element can be from the center of the viewport before being considered "visible"
}

export default function useOnScreen(
    ref: RefObject<HTMLElement>,
    options: UseOnScreenOptions = {}
) {
    const [isOnScreen, setIsOnScreen] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(
                ([entry]) => {
                    return setIsOnScreen(entry.isIntersecting);
                },
                {
                    threshold: options.threshold,
                }
            ),
        [ref]
    );

    useEffect(() => {
        if (ref.current === null) return;

        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, []);

    return isOnScreen;
}
