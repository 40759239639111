import { AcfImage } from "../../Data/dtos";
import "./styles.scss";

interface ImageMarqueeProps {
    images?: AcfImage[];
}

const ImageMarquee = ({ images }: ImageMarqueeProps) => {
    return (
        <div className="image-marquee">
            {(() => {
                const content = [];
                for (let i = 0; i < 2; i++) {
                    content.push(
                        <div
                            className="marquee-content gap-12 lg:gap-8"
                            key={`marquee-content-${i}`}
                        >
                            {images?.map((image, index) => (
                                <div
                                    className="marquee-image"
                                    key={`marquee-image-${index}`}
                                >
                                    {image.url && (
                                        <img
                                            src={image.url}
                                            alt={
                                                image.altText ||
                                                `marquee-image-${index}`
                                            }
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    );
                }
                return content;
            })()}
        </div>
    );
};

export default ImageMarquee;
