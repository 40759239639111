import { AcfTwoColumnText } from "../../Data/dtos";

const TwoColumnTextBlock = (props: AcfTwoColumnText) => {
    const { sectionNumber, textLeft, textRight, backgroundColor, borderColor } =
        props;
    const colorClasses = [`bg-bc-${backgroundColor}`].join(" ");

    return (
        <div className="container mx-auto py-0">
            <div
                className={`border-0 rounded-lg grid grid-cols-12 ${
                    backgroundColor !== "transparent" ? "p-12" : "py-12"
                } gap-4 items-center ${colorClasses}`}
            >
                <div className="col-span-12 lg:col-span-1 pb-6 lg:pb-0">
                    <p className="card-number">
                        {parseFloat(sectionNumber).toFixed(1)}
                    </p>
                </div>
                <div
                    className="col-span-12 lg:col-span-5 pb-6 lg:pb-0"
                    dangerouslySetInnerHTML={{ __html: textLeft! }}
                ></div>

                <div
                    className="col-span-12 lg:col-span-6"
                    dangerouslySetInnerHTML={{ __html: textRight! }}
                ></div>
            </div>
        </div>
    );
};

export default TwoColumnTextBlock;
