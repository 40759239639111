import { useState, useEffect } from "react";
import parser from "html-react-parser";
import { Helmet } from "react-helmet-async";
import Spacer from "../Components/Spacer/Spacer";
import BasePage from "./Base";
import { useGetNewsPostQuery, useGetNewsQuery } from "../generated/graphql";
import Loading from "../Components/Loading/Loading";
import NewsBanner from "../Components/NewsBanner/NewsBanner";
import NewsPostPreview from "../Components/NewsPostPreview/NewsPostPreview";
import { NewsPost } from "../Data/dtos";
import DataUtilities from "../Data/Utilities";
import { useParams } from "react-router-dom";

const NewsSingle = () => {
    const { slug } = useParams<{ slug: string }>();
    const { data, loading, error } = useGetNewsPostQuery({
        variables: { uri: `/news/${slug}` || "" },
    });
    const {
        data: additionalPostsData,
        loading: additionalPostsLoading,
        error: additionalPostsError,
    } = useGetNewsQuery();
    const [additionalPosts, setAdditionalPosts] = useState<NewsPost[]>([]);
    const [postData, setPostData] = useState<NewsPost | null>(null);
    const [seoData, setSeoData] = useState<{
        title: string;
        fullHead: string;
    } | null>(null);

    const additionalPostLimit = 3;

    // side effect for single post data retrieved
    useEffect(() => {
        if (data && !error) {
            const formattedPost = DataUtilities.flattenNewsPostData(
                data.postBy
            );

            setPostData(formattedPost);

            if (data?.postBy?.seo) {
                setSeoData((data?.postBy as any)?.seo);
            }
        }
    }, [data, error]);

    //side effect for additional posts data retrieved
    useEffect(() => {
        if (
            !error &&
            postData &&
            additionalPostsData &&
            !additionalPostsError
        ) {
            const formattedPosts = additionalPostsData?.posts?.nodes
                .map((post: any) => DataUtilities.flattenNewsPostData(post))
                .filter((post: NewsPost) => {
                    return !post.uri.includes(`/news/${slug}`);
                });

            setAdditionalPosts(formattedPosts || []);
        }
    }, [additionalPostsData, additionalPostsError, postData, error]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        // show error
        console.log(error);
    }

    return (
        <BasePage>
            <Helmet>
                <title>{seoData?.title}</title>
                {parser(seoData?.fullHead || "")}
            </Helmet>
            {postData && (
                <NewsBanner
                    title={postData?.title}
                    isSinglePost={true}
                    image={postData?.featuredImage}
                    fresh={postData?.fresh}
                />
            )}
            <div className="max-w-2xl mx-auto">
                <div
                    className="post-body my-8 py-8"
                    dangerouslySetInnerHTML={{ __html: data?.postBy?.content! }}
                ></div>
            </div>
            <div className="container mx-auto">
                <div className="next-posts border-t border-bc-soft-black mt-12 pt-12 mb-12">
                    <Spacer size="md" />
                    <header className="mb-12">
                        <h2 className="serif">Up next for you</h2>
                    </header>

                    <ul className="next-up-posts grid grid-cols-12 grid-rows-auto gap-4">
                        {additionalPosts?.map((post, index) => {
                            if (index >= additionalPostLimit) {
                                return null;
                            }
                            return <NewsPostPreview key={post.id} {...post} />;
                        })}
                    </ul>
                </div>
            </div>
        </BasePage>
    );
};

export default NewsSingle;
