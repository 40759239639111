import { Link } from "react-router-dom";
import DroppedPin from "../../assets/icons/dropped-pin.svg";
import MailtoIcon from "../../assets/icons/mail-to.svg";
import { CompanyLocationData, AcfConnectLocations } from "../../Data/dtos";
import "./styles.scss";

const Locations = ({
    locations,
    data,
}: {
    locations?: CompanyLocationData[];
    data: AcfConnectLocations;
}) => {
    return (
        <section>
            <div
                id="locations"
                className="connect-locations container mx-auto relative py-12"
            >
                <span className="container-card bg-bc-light-blue rounded-lg"></span>
                <div className="grid grid-cols-12 grid-rows-1 gap-10 items-center">
                    <div className="col-span-12 md:col-span-3 lg:col-span-5 flex flex-col gap-6">
                        <h4>{data?.superTitle}</h4>
                        <h1>{data?.header}</h1>
                        <div
                            dangerouslySetInnerHTML={{ __html: data?.content }}
                        ></div>
                    </div>
                    <div className="col-span-12 md:col-span-9 lg:col-span-7">
                        <ul className="flex flex-col bc-border-list">
                            {locations
                                ?.sort((loc1, loc2) => {
                                    const name1 = loc1.name.toUpperCase();
                                    const name2 = loc2.name.toUpperCase();
                                    if (name1 < name2) {
                                        return -1;
                                    }
                                    if (name1 > name2) {
                                        return 1;
                                    }
                                    return 0;
                                })
                                .map((location, index) => (
                                    <li
                                        className="py-4 border-bc-soft-black grid grid-cols-12 items-center"
                                        key={`location-${index}`}
                                    >
                                        <div className="col-span-12 md:col-span-3 pb-4 md:pb-0">
                                            <p>{location?.name}</p>
                                        </div>
                                        <Link
                                            to={location.googleLink}
                                            target="_blank"
                                            className="map-link col-span-8 md:col-span-6 flex items-center gap-4 hover:text-bc-bright-blue"
                                        >
                                            <span>
                                                <img
                                                    className="pin-icon"
                                                    src={DroppedPin}
                                                    alt="map pin"
                                                />
                                            </span>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: location?.address,
                                                }}
                                            ></div>
                                        </Link>
                                        <div className="col-span-4 pr-4 md:pr-0 md:col-span-3 text-right">
                                            <Link
                                                className="mail-link"
                                                to={`mailto:${location?.contactEmail}`}
                                                target="_blank"
                                            >
                                                <svg
                                                    className="inline"
                                                    width="40"
                                                    height="40"
                                                    viewBox="0 0 40 40"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        className="envelope-flap"
                                                        d="M5.00377 6.5L14.5718 16.0672C15.3601 16.8554 16.6408 16.847 17.4186 16.0484L26.719 6.5"
                                                        stroke="#2885FF"
                                                        strokeWidth="3"
                                                    />
                                                    <path
                                                        className="envelope-crease"
                                                        d="M5.00377 6.5L14.5718 16.0672C15.3601 16.8554 16.6408 16.847 17.4186 16.0484L26.719 6.5"
                                                        stroke="#2885FF"
                                                        strokeWidth="3"
                                                    />
                                                    <rect
                                                        x="2"
                                                        y="9"
                                                        width="28"
                                                        height="19"
                                                        rx="2"
                                                        stroke="#2885FF"
                                                        strokeWidth="3"
                                                    />
                                                </svg>
                                            </Link>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Locations;
