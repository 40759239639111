import { AcfServiceColumns } from "../../Data/dtos";

const ServiceColumns = (props: AcfServiceColumns) => {
    const { sectionNumber, header, icon, columns } = props;
    return (
        <div className="container mx-auto py-8">
            <div className="grid grid-cols-12 gap-4 mb-12 items-center">
                <div className="col-span-12 lg:col-span-1">
                    <p className="card-number">
                        {parseFloat(sectionNumber).toFixed(1)}
                    </p>
                </div>
                <div className="col-span-12 lg:col-span-5">
                    <h3>{header}</h3>
                </div>
                <div className="col-span-12 lg:col-span-6 text-right">
                    <img
                        className="services-header-icon inline"
                        src={icon?.url}
                        alt={icon?.altText}
                        style={{ maxWidth: "3rem" }}
                    />
                </div>
            </div>
            <div className="grid grid-cols-12 gap-4">
                {columns?.map((column, index) => {
                    return (
                        <div
                            className="col-span-12 lg:col-span-4 flex gap-4 flex-col"
                            key={`service-column-${index}`}
                        >
                            <img
                                src={column.image.url}
                                alt={column.image.altText}
                                className="rounded-lg"
                            />

                            <div className="capability-description relative bg-bc-soft-black text-bc-soft-white p-6 lg:p-8 xl:p-12 rounded-lg flex-grow">
                                <header className="mb-6 flex justify-between items-center">
                                    <p className="card-number">
                                        {column.sectionNumber &&
                                            parseFloat(
                                                column.sectionNumber
                                            ).toFixed(1)}
                                    </p>
                                    <img
                                        className="service-column-icon"
                                        src={column.icon.url}
                                        alt={column.icon.altText}
                                        style={{ maxWidth: "3rem" }}
                                    />
                                </header>

                                <h1 className="text-bc-soft-white mb-8 mt-4">
                                    {column.header}
                                </h1>
                                <div
                                    className="mt-4"
                                    dangerouslySetInnerHTML={{
                                        __html: column.content,
                                    }}
                                ></div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ServiceColumns;
