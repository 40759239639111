import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Link } from "react-router-dom";
import { Colors } from "../../Types/Global";
import Button from "../Buttons/Button";
import { AcfNewsItem, AcfLink } from "../../Data/dtos";
import "./styles.scss";

interface NewsItemsProps {
    link: AcfLink;
    items: AcfNewsItem[];
}

const NewsItem = (props: AcfNewsItem & { index: number }) => {
    const { fresh, title, text, categories, uri: url, image, index } = props;

    const indexedColorClasses = [
        "bg-bc-soft-white border-bc-soft-black border with-image",
        "bg-bc-light-blue",
        "bg-bc-soft-white border-bc-soft-black border with-image",
        "bg-bc-salmon text-bc-soft-white",
        "bg-bc-soft-white border-bc-soft-black border with-image",
        "bg-bc-chartreuse",
    ];

    const colorIndex = index % indexedColorClasses.length;

    const getTextColorClass = (backgroundColor: Colors) => {
        switch (backgroundColor) {
            case Colors.salmon:
            case Colors["soft-black"]:
            case Colors.navy:
                return "text-bc-soft-white";
            default:
                return "text-bc-soft-black";
        }
    };

    const styleClasses = [
        "rounded-lg news-card overflow-hidden",
        fresh ? "news-card-fresh" : "",
        indexedColorClasses[colorIndex],
    ].join(" ");

    return (
        <div className={styleClasses}>
            {image && (
                <header
                    className="news-item-img w-full h-md"
                    style={{ backgroundImage: `url(${image.url})` }}
                ></header>
            )}
            <div className="news-item-content p-6 lg:p-8 xl-p-12">
                <p className="text-xs">{categories?.join(", ")}</p>
                <div className="mt-6 mb-10">
                    <h2 className="serif leading-8 tracking-wide">{title}</h2>
                </div>

                <div dangerouslySetInnerHTML={{ __html: text }}></div>
                <div className="mt-12 mb-4">
                    <Button
                        to={url}
                        dark={indexedColorClasses[colorIndex].includes(
                            "salmon"
                        )}
                        size="sm"
                        text={`Read on!`}
                    />
                </div>
            </div>
        </div>
    );
};

const NewsItems = ({ items, link }: NewsItemsProps) => {
    return (
        <>
            <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
                <Masonry gutter="1rem">
                    {items.map((item, index) => (
                        <NewsItem
                            key={`news-item-${index}`}
                            {...item}
                            index={index}
                        />
                    ))}
                </Masonry>
            </ResponsiveMasonry>
            <footer className="news-footer flex justify-end mt-8 gap-8 xl:gap-12 items-center">
                <h1>Want more?</h1>
                <Button to={link?.url} text={link?.title} size="sm" />
            </footer>
        </>
    );
};

export default NewsItems;
