import { Link } from "react-router-dom";
import Button from "../Buttons/Button";
import FreshIcon from "../../assets/icons/fresh.svg";
import { NewsPreview as NewPreviewProps } from "../../Data/dtos";

const NewsPreview = (props: NewPreviewProps) => {
    const { sectionNumber, header, subhead, featuredNews, otherNews, link } =
        props;

    return (
        <div className="news-preview flex flex-col justify-between">
            <header className="pt-4">
                <p className="card-number">{sectionNumber}</p>
                <h2 className="serif my-4">{header}</h2>

                <p className="mb-6">{subhead}</p>
            </header>
            <div className="news-posts">
                {/* promoted posts */}
                <header className="grid grid-cols-2 gap-8">
                    {featuredNews.map((post, index) => (
                        <div key={`promoted-post-${index}`}>
                            <Link to={post.uri}>
                                <div
                                    className="img-frame rounded-lg overflow-hidden mb-2 relative"
                                    style={{ height: "6rem" }}
                                >
                                    <div
                                        className="bleed-image"
                                        style={{
                                            backgroundImage: `url(${post.image.url})`,
                                        }}
                                    >
                                        {post.fresh && (
                                            <img
                                                src={FreshIcon}
                                                alt="Fresh"
                                                className="fresh-icon absolute top-0 right-0"
                                                style={{
                                                    width: "25%",
                                                    transform:
                                                        "translate(-10%, 50%) rotate(22.5deg)",
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Link>
                            <Button
                                to={post.uri}
                                text={post.title}
                                clip={true}
                                newsTitleGradient={true}
                                size="sm"
                            />
                        </div>
                    ))}
                </header>

                <ul className="bc-border-list my-8">
                    {otherNews.map((post, index) => (
                        <li className="py-2" key={`listed-post-${index}`}>
                            <Button
                                to={post.uri}
                                text={post.title}
                                clip={true}
                                newsTitleGradient={true}
                                badge={
                                    post.fresh ? (
                                        <img
                                            src={FreshIcon}
                                            alt="Fresh"
                                            style={{
                                                width: "2.5rem",
                                            }}
                                        />
                                    ) : null
                                }
                                size="sm"
                            />
                        </li>
                    ))}
                </ul>
            </div>
            <div className="text-right">
                <Button size="sm" to="/news" text={link?.title} />
            </div>
        </div>
    );
};

export default NewsPreview;
