import { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import InputWithSubmitButton from "../InputWithSubmitButton/InputWithSubmitButton";
import DataUtilities from "../../Data/Utilities";
import { motion } from "framer-motion";
import Modal from "./Modal";
import { useLocation } from "react-router-dom";
import "./styles.scss";

interface DownloadFormProps {
    open: boolean;
    onClose: () => void;
    onSuccess?: () => void;
}

const DownloadForm = ({ open, onClose }: DownloadFormProps) => {
    const { pathname, key } = useLocation();
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (values: any) => {
        const baseUrl = process.env.REACT_APP_API_URL;
        const resp = await fetch(`${baseUrl || ""}/wp-json/barcode/contact`, {
            method: "POST",
            body: JSON.stringify(values),
        });

        if (resp.status === 200) {
            setSuccess(true);

            if (onClose) {
                window.setTimeout(() => {
                    onClose();
                }, 1500);
            }
        }
    };

    return (
        <Modal open={open} onClose={onClose} border={true}>
            <div className="download-form">
                <header className="mb-12 relative">
                    <h4>Download Our Insights</h4>
                    <h1 className="text-bc-salmon mt-6">Getting smarter?</h1>
                    <h1 className="text-bc-salmon">
                        Enter your email and access our library of past reports.
                    </h1>

                    <h6 className="serif mt-6">
                        Trend, Tradeshow Recaps, Industry Insights and more!
                    </h6>
                </header>

                <Formik
                    initialValues={{
                        hutk: DataUtilities.getCookie("hubspotutk"),
                        pageUrl: window.location.href,
                        pageName: document.title || "",
                        formType: "download",
                        firstName: "",
                        lastName: "",
                        jobTitle: "",
                        company: "",
                        email: "",
                    }}
                    validate={(values) => {
                        const errors: any = {};
                        const emailPattern =
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                        const requiredFieldErrorMessage =
                            "This field is required";

                        if (!values.firstName) {
                            errors.firstName = requiredFieldErrorMessage;
                        }

                        if (!values.lastName) {
                            errors.lastName = requiredFieldErrorMessage;
                        }

                        if (!values.company) {
                            errors.company = requiredFieldErrorMessage;
                        }

                        if (!values.email) {
                            errors.email = requiredFieldErrorMessage;
                        } else if (!emailPattern.test(values.email)) {
                            errors.email = "A valid email is required";
                        }

                        return errors;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);

                        const resp = await handleSubmit(values);

                        setSubmitting(false);
                    }}
                >
                    <Form className="download-access-form grid grid-cols-2 gap-4">
                        <div className="col-span-2 md:col-span-1">
                            <Field
                                className="form-field hover:placeholder-bc-bright-blue"
                                placeholder="First Name"
                                name="firstName"
                            />
                            <ErrorMessage
                                className="form-field-error"
                                name="firstName"
                                component="div"
                            />
                        </div>

                        <div className="col-span-2 md:col-span-1">
                            <Field
                                className="form-field hover:placeholder-bc-bright-blue"
                                placeholder="Last Name"
                                name="lastName"
                            />
                            <ErrorMessage
                                className="form-field-error"
                                name="lastName"
                                component="div"
                            />
                        </div>

                        <div className="col-span-2 md:col-span-1">
                            <Field
                                className="form-field hover:placeholder-bc-bright-blue"
                                placeholder="Job Title"
                                name="jobTitle"
                            />
                            <ErrorMessage
                                className="form-field-error"
                                name="jobTitle"
                                component="div"
                            />
                        </div>

                        <div className="col-span-2 md:col-span-1">
                            <Field
                                className="form-field hover:placeholder-bc-bright-blue"
                                placeholder="Company"
                                name="company"
                            />
                            <ErrorMessage
                                className="form-field-error"
                                name="company"
                                component="div"
                            />
                        </div>

                        <div className="col-span-2">
                            <InputWithSubmitButton
                                name="email"
                                type="email"
                                placeholder="Your email"
                            />
                            <ErrorMessage
                                className="form-field-error"
                                name="email"
                                component="div"
                            />
                        </div>

                        <div className="col-span-2 md:col-span-1">
                            <label
                                className="bc-checkbox-label"
                                htmlFor="newsletter"
                            >
                                <Field
                                    className="bc-checkbox"
                                    id="newsletter"
                                    type="checkbox"
                                    name="newsletter"
                                />
                                <b></b>
                                {` `}
                                I'd like to receive emails
                            </label>
                        </div>
                    </Form>
                </Formik>
                {success && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="my-4 success-message text-center"
                    >
                        <p className="text-bc-bright-blue font-bold">
                            Thanks. Please check your email for a link.
                        </p>
                    </motion.div>
                )}
            </div>
        </Modal>
    );
};

export default DownloadForm;
