export enum Colors {
    "transparent" = "transparent",
    "light-blue" = "light-blue",
    "bright-blue" = "bright-blue",
    "navy" = "navy",
    "salmon" = "salmon",
    "peach" = "peach",
    "chartreuse" = "chartreuse",
    "soft-black" = "soft-black",
    "soft-white" = "soft-white",
    "main-neutral" = "main-neutral",
    "copper" = "copper",
    "darker-neutral" = "darker-neutral",
}

export default {
    Colors,
};

export type HomepageBannerData = {
    backgroundColor: string;
    borderColor: string;
    content: string;
    image: string;
    text: string;
};
