import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import parse from "html-react-parser";
import Loading from "../Components/Loading/Loading";
import SpecialBanner from "../Components/SpecialBanner/SpecialBanner";
import BasePage from "./Base";
import { AcfShoppingMaps } from "../Data/dtos";
import { useGetShoppingMapsPageDataQuery } from "../generated/graphql";
import DataUtilities from "../Data/Utilities";
import { Link } from "react-router-dom";
import ArrowIcon from "../Components/Arrow/Arrow";
import Spacer from "../Components/Spacer/Spacer";
import { Modal, useModals } from "../Providers/Modal";
import DropDownButton from "../Components/Buttons/DropdownButton";

import "./styles.scss";

const ShoppingMaps = () => {
    const { data, loading, error } = useGetShoppingMapsPageDataQuery();
    const [shoppingMapPageData, setShoppingMapPageData] = useState<any>(null);
    const [seoData, setSeoData] = useState<{
        title: string;
        fullHead: string;
    } | null>(null);
    const Modals = useModals();

    useEffect(() => {
        if (data && !error) {
            const blocks = (data?.nodeByUri as any)?.editorBlocks;
            setShoppingMapPageData(DataUtilities.flattenEditorBlocks(blocks));
            setSeoData((data?.nodeByUri as any)?.seo);
        }
    }, [data, error, setSeoData, setShoppingMapPageData]);

    const bannerContent = shoppingMapPageData?.find(
        (block: any) => block.name === "acf/shopping-map-banner"
    );

    const maps: AcfShoppingMaps = shoppingMapPageData?.find(
        (block: any) => block.name === "acf/shopping-maps"
    );

    const cta = shoppingMapPageData?.find(
        (block: any) => block.name === "acf/shopping-map-cta"
    );

    if (loading) {
        return <Loading />;
    }

    return (
        <BasePage>
            <Helmet>
                <title>{seoData?.title}</title>
                {parse(seoData?.fullHead || "")}
            </Helmet>
            <div className="mb-8 map-page">
                <SpecialBanner {...bannerContent} title="Shopping Maps" />

                <div className="container mx-auto">
                    <ul className="shopping-map-list grid grid-cols-12 grid-rows-auto gap-8 pb-12">
                        {maps?.maps?.map((map, index) => {
                            return (
                                <li
                                    className="bg-bc-bright-blue rounded-lg text-white col-span-12 md:col-span-6 lg:col-span-4 relative"
                                    key={`map-link-${index}`}
                                >
                                    <Link
                                        className="map-link block py-12 px-8 flex"
                                        to={map?.link.url}
                                        target={map?.link?.target}
                                    >
                                        <h3 className="text-white ">
                                            {map?.title}
                                        </h3>
                                        <ArrowIcon
                                            dark={true}
                                            width={"2.5rem"}
                                        />
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <Spacer size={"md"} />
                <div className="container mx-auto">
                    <footer className="border-t border-bc-soft-black py-8">
                        <Spacer size={"sm"} />
                        <div className="grid grid-cols-12 gap-8">
                            <div className="col-span-12 lg:col-span-8 border-2 rounded-lg border-bc-salmon py-12 px-8">
                                <div className="grid grid-cols-2">
                                    <div className="col-span-2 lg:col-span-1 py-8">
                                        <div className="flex flex-col gap-8">
                                            <h4>{cta?.superTitle}</h4>
                                            <h1>{cta?.title}</h1>
                                        </div>
                                    </div>
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className="flex flex-col gap-4">
                                            <DropDownButton
                                                text="Read News"
                                                isDownload={false}
                                                to={`/news`}
                                            />
                                            <DropDownButton
                                                text="Download Insights"
                                                onClick={() => {
                                                    Modals.openModal(
                                                        Modal.DOWNLOADS
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12 lg:col-span-4 rounded-lg relative overflow-hidden">
                                <div
                                    className="bleed-image"
                                    style={{
                                        backgroundImage: `url(${cta?.image?.url})`,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </BasePage>
    );
};

export default ShoppingMaps;
