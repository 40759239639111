import { AcfImageCarousel } from "../../Data/dtos";
import { Carousel } from "react-responsive-carousel";
import "./styles.scss";

const CultureImageCarousel = ({ images }: AcfImageCarousel) => {
    return (
        <section>
            <div className="culture-image-carousel pb-8">
                <Carousel
                    infiniteLoop={true}
                    centerMode={true}
                    centerSlidePercentage={50}
                    emulateTouch={false}
                    showIndicators={true}
                    showThumbs={false}
                    showStatus={false}
                    autoPlay={true}
                    renderIndicator={(
                        onClickHandler,
                        isSelected,
                        index,
                        label
                    ) => {
                        const defStyle = {
                            marginLeft: 20,
                            color: "white",
                            cursor: "pointer",
                        };
                        const style = isSelected
                            ? { ...defStyle, color: "red" }
                            : { ...defStyle };
                        return (
                            <span
                                className={`bc-slide-indicator ${
                                    isSelected ? "active" : ""
                                }`}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                key={index}
                                role="button"
                                tabIndex={0}
                                aria-label={`${label} ${index + 1}`}
                            ></span>
                        );
                    }}
                >
                    {images?.map((image, index) => (
                        <div
                            className="culture-img-slide rounded-lg"
                            key={`culture-img-${index}`}
                            style={{
                                backgroundImage: `url(${image.url})`,
                            }}
                            title={image.altText}
                        ></div>
                    ))}
                </Carousel>
            </div>
        </section>
    );
};

export default CultureImageCarousel;
