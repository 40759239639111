import { useState } from "react";
import DownloadFormModal from "../Modals/Downloads";
import DropDownButton from "../Buttons/DropdownButton";
import { useModals, Modal } from "../../Providers/Modal";

interface GetAccessProps {
    title?: string;
    superTitle?: string;
    copy?: string;
    buttonText?: string;
}

const GetAccess = ({
    title = "Download our insights.",
    superTitle = "Valuable industry reports",
    copy = "<p>We're constantly gaining industry knowledge: from tips and tricks to back end data, we got crazy amounts of knowledge ready to share with you.</p>",
    buttonText = "Get access",
}: GetAccessProps) => {
    const Modals = useModals();

    return (
        <>
            <div className="border border-2 border-bc-salmon rounded-lg">
                <div className="p-8 xl:p-10">
                    <header className="flex flex-col gap-4">
                        <h4>{superTitle}</h4>
                        <h2 className="serif text-bc-salmon">{title}</h2>

                        <div dangerouslySetInnerHTML={{ __html: copy }}></div>
                    </header>

                    <div className="mt-8">
                        <DropDownButton
                            text={buttonText}
                            onClick={() => {
                                Modals.openModal(Modal.DOWNLOADS);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default GetAccess;
