import { HTMLProps, useState } from "react";

interface TeamFilterCategoryBtnProps
    extends Omit<HTMLProps<HTMLButtonElement>, "type"> {
    category: string;
    active: boolean;
}

const TeamFilterCategoryBtn = ({
    category,
    active,
    ...props
}: TeamFilterCategoryBtnProps) => {
    return (
        <button
            className={`team-filter-category-btn text-bc-bright-blue flex items-center gap-2 ${
                active ? "active" : ""
            }`}
            {...props}
        >
            {category}{" "}
            <svg
                className="filter-category-btn-arrow stroke-bc-bright-blue "
                width="20"
                height="13"
                viewBox="0 0 20 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M2 2L10.102 10L18 2" strokeWidth="3" />
            </svg>
        </button>
    );
};

export default TeamFilterCategoryBtn;
