import {
    ReactNode,
    createContext,
    useContext,
    useState,
    useEffect,
} from "react";

import { AnimatePresence } from "framer-motion";

import DownloadAccessModal from "../Components/Modals/Downloads";
import NewsletterModal from "../Components/Modals/Newsletter";

export enum Modal {
    DOWNLOADS = "DOWNLOADS",
    CONTACT = "CONTACT",
    NEWSLETTER = "NEWSLETTER",
}

interface ModalState {
    open: boolean; // whether or not a modal is open
    modal: Modal | null; // currently active modal
    openModal: (modal: Modal) => void; // open a modal
    closeActiveModal: () => void; // close the active modal
    onModalOpen?: () => void; // callback for when a modal is opened
    onModalClose?: () => void; // callback for when a modal is closed
}

const ModalContext = createContext<ModalState>(undefined as any);

export function useModals() {
    return useContext(ModalContext);
}

export const ModalProvider = ({ children }: { children: ReactNode }) => {
    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState<Modal | null>(Modal.DOWNLOADS);

    const openModal = (modal: Modal) => {
        setModal(modal);
        setOpen(true);
    };

    const closeActiveModal = () => {
        setOpen(false);
        setModal(null);
    };

    return (
        <ModalContext.Provider
            value={{
                open,
                modal,
                openModal,
                closeActiveModal,
            }}
        >
            {children}

            <AnimatePresence mode="wait">
                {modal === Modal.DOWNLOADS && (
                    <DownloadAccessModal
                        open={open}
                        onClose={() => {
                            closeActiveModal();
                        }}
                    />
                )}
                {modal === Modal.NEWSLETTER && (
                    <NewsletterModal
                        open={open}
                        onClose={() => {
                            closeActiveModal();
                        }}
                    />
                )}
            </AnimatePresence>
        </ModalContext.Provider>
    );
};
