import { AcfCompanyBenefits } from "../../Data/dtos";
import Section from "../Section/Section";
import VerticalAccordionList from "../VerticalAccordionList/VerticalAccordionList";
import "./styles.scss";

const CompanyBenefits = (props: AcfCompanyBenefits) => {
    const { image, header, content } = props;

    return (
        <Section>
            <div className="container mx-auto company-benefits px-0">
                <div className="border-0 rounded-lg flex gap-4 flex-wrap lg:flex-nowrap">
                    <div className="company-benefits-image-wrapper w-full lg:w-1/2 p-8 relative">
                        {image && (
                            <div
                                className="company-benefits-image rounded-lg"
                                style={{
                                    backgroundImage: `url(${image?.url})`,
                                }}
                            ></div>
                        )}
                    </div>
                    <div className="company-benefits-accordion-wrapper w-full lg:w-1/2 border border-bc-bright-blue border-2 rounded-lg">
                        <VerticalAccordionList
                            name="Company Benefits"
                            header={header}
                            accordion={content?.map((item) => {
                                return {
                                    title: item.title,
                                    text: item.text,
                                };
                            })}
                        />
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default CompanyBenefits;
