import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spacer from "../Spacer/Spacer";
import Section from "../Section/Section";
import ArrowIcon from "../Arrow/Arrow";
import useOnScreen from "../../Hooks/IsVisible";
import HelloSticker from "../../assets/icons/hello-sticker.svg";
import MeetSticker from "../../assets/icons/please-to-meet-you-sticker.svg";
import { AcfLink } from "../../Data/dtos";
import "./styles.scss";

const Join = ({
    header,
    content,
    link,
}: {
    header: string;
    content: string;
    link: AcfLink;
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [animationTriggered, setAnimationTriggered] = useState(false);
    const isVisible = useOnScreen(ref, { threshold: 1 });

    useEffect(() => {
        if (animationTriggered) return;

        if (isVisible) {
            setAnimationTriggered(true);
        }
    }, [isVisible]);

    const styles = {
        height: "34vw",
        maxHeight: "20.625rem",
        minHeight: "fit-content",
    };

    return (
        <Section>
            <div className={`join-us ${animationTriggered ? "in-view" : ""}`}>
                <div className="container mx-auto px-0" ref={ref}>
                    <div className="join-content border rounded-lg p-8 border-bc-soft-black border-2 grid grid-cols-12 items-center relative overflow-hidden">
                        <div
                            className="stickers absolute top-0 left-0 w-full h-full"
                            style={{ zIndex: "-1" }}
                        >
                            <img
                                src={HelloSticker}
                                alt="Hello Sticker"
                                className="sticker-1 absolute top-0 right-0"
                                style={{
                                    zIndex: "-1",
                                    width: "150px",
                                    filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.075))",
                                }}
                            />
                            <img
                                src={MeetSticker}
                                alt="Please to meet you sticker"
                                className="sticker-2 absolute top-0 right-0"
                                style={{
                                    zIndex: "-1",
                                    width: "120px",
                                    filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.075))",
                                }}
                            />
                        </div>

                        <div className="col-span-12 lg:col-span-6">
                            <div className="max-w-lg">
                                <div className="mb-4">
                                    <h1>{header}</h1>
                                </div>

                                <div
                                    className="text-sm"
                                    dangerouslySetInnerHTML={{
                                        __html: content,
                                    }}
                                ></div>
                            </div>
                        </div>
                        <div className="col-span-12 lg:col-span-6 flex justify-between">
                            <Link to={link?.url} target={link?.target}>
                                <h2>{link?.title}</h2>
                            </Link>
                            <ArrowIcon width={`2.5rem`} />
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default Join;
