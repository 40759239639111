import { useState } from "react";
import { Link } from "react-router-dom";
import { AcfLink } from "../../Data/dtos";
import ArrowIcon from "../Arrow/Arrow";
import DataUtilities from "../../Data/Utilities";

import "./styles.scss";

interface ShopWithUsProps {
    number: string;
    title: string;
    mapImage: string;
    link: AcfLink;
}

const ShopWithUs = ({ number, title, mapImage, link }: ShopWithUsProps) => {
    const [hovering, setHovering] = useState(false);

    return (
        <Link
            to={DataUtilities.localizeUrl(link.url)}
            className="shop-with-us grid grid-cols-2 gap-4 h-full"
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            <div className="shop-with-card tile bg-bc-bright-blue text-white rounded-lg p-6 col-span-2 sm:col-span-1 flex items-center justify-center relative">
                <p
                    className="card-number mr-2 absolute"
                    style={{
                        top: "2rem",
                        left: "2rem",
                    }}
                >
                    {number}
                </p>
                <h3 className="text-white block ">{title}</h3>
                <div className="arrows">
                    {/* <ArrowIcon
                        strokeColorClass="stroke-bc-chartreuse"
                        width={"2rem"}
                    /> */}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 22.03 21.95"
                        width="2rem"
                        className={`shop-arrow`}
                    >
                        <g fill="none" strokeWidth="3">
                            <path d="M9.99 1.06l9.92 9.91-9.92 9.92M19.48 10.97H0h19.48" />
                        </g>
                    </svg>
                    {/* <ArrowIcon
                        strokeColorClass="stroke-bc-chartreuse"
                        width={"2rem"}
                    /> */}
                    {/* <ArrowIcon
                        strokeColorClass="stroke-bc-chartreuse"
                        width={"2rem"}
                    /> */}
                </div>
            </div>
            <div className="shop-with-card tile col-span-2 sm:col-span-1 p-6 relative border-2 border-bc-bright-blue overflow-hidden rounded-lg">
                <div className="map-image-wrapper rounded-lg">
                    <div
                        className="map-image"
                        style={{
                            backgroundImage: `url(${mapImage})`,
                        }}
                    ></div>
                    <ArrowIcon
                        strokeColorClass="stroke-bc-chartreuse"
                        width={"2.5rem"}
                    />
                </div>
            </div>
        </Link>
    );
};

export default ShopWithUs;
