import { Link, LinkProps } from "react-router-dom";
import ArrowIcon from "../Arrow/Arrow";
import { HTMLProps } from "react";
import "./styles.scss";

type FancyButtonProps = Omit<HTMLProps<HTMLButtonElement>, "type"> &
    Omit<HTMLProps<HTMLAnchorElement>, "type"> & {
        text: string;
        to?: string;
        external?: boolean;
    };

const ExternalButton = ({ text, to, ...props }: FancyButtonProps) => {
    const { ref, ...attrs } = props;
    const arrowWidth = "1.5rem";

    const classNames = [
        "external-button",
        "border-2 border-bc-soft-black p-2 rounded-lg text-bc-soft-black hover:text-bc-bright-blue",
        props.className,
    ].join(" ");

    return (
        <Link to={to!} className={classNames} target={"_blank"} {...attrs}>
            <span>{text}</span>
            <ArrowIcon width={arrowWidth} strokeColorClass="stroke-bc-salmon" />
        </Link>
    );
};

export default ExternalButton;
