import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { motion } from "framer-motion";
import DataUtilities from "../../Data/Utilities";
import InputWithSubmitButton from "../InputWithSubmitButton/InputWithSubmitButton";
import { useLocation } from "react-router-dom";

const NewsletterSignup = ({
    onSuccess,
    title = "Subscribe to our newsletter",
    content = "Read our newsletter for the latest from The Barcode Group. Learn what's happening in the world of retail and stay up-to-date on exclusive content, news and valuable resources.",
}: {
    onSuccess?: () => void;
    title?: string;
    content?: string;
}) => {
    const [success, setSuccess] = useState(false);
    const { pathname, key } = useLocation();

    const handleSubmit = async (values: any) => {
        const baseUrl = process.env.REACT_APP_API_URL;
        const resp = await fetch(`${baseUrl}/wp-json/barcode/contact`, {
            method: "POST",
            body: JSON.stringify(values),
        });

        if (resp.status === 200) {
            setSuccess(true);
            if (onSuccess) {
                window.setTimeout(() => {
                    onSuccess();
                }, 1500);
            }
        }
    };

    return (
        <div style={{ maxWidth: "24rem" }}>
            <h2 className="serif mb-4">{title}</h2>
            <p>{content}</p>

            <footer className="mt-8">
                <Formik
                    initialValues={{
                        hutk: DataUtilities.getCookie("hubspotutk"),
                        pageUrl: window.location.href,
                        pageName: document.title || "",
                        formType: "newsletter",
                        firstName: "",
                        lastName: "",
                        email: "",
                    }}
                    validate={(values) => {
                        const errors: any = {};
                        const emailPattern =
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                        if (!values.firstName) {
                            errors.firstName = "This field is required";
                        }

                        if (!values.lastName) {
                            errors.lastName = "This field is required";
                        }

                        if (!values.email) {
                            errors.email = "This field is required";
                        } else if (!emailPattern.test(values.email)) {
                            errors.email = "A valid email address is required";
                        }

                        return errors;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);

                        const resp = await handleSubmit(values);

                        setSubmitting(false);
                    }}
                >
                    <Form className="grid grid-cols-2 gap-4">
                        <div className="col-span-1">
                            <Field
                                className="form-field hover:placeholder-bc-bright-blue"
                                placeholder="First Name"
                                name="firstName"
                            />
                            <ErrorMessage
                                className="form-field-error"
                                name="firstName"
                                component="div"
                            />
                        </div>
                        <div className="col-span-1">
                            <Field
                                className="form-field hover:placeholder-bc-bright-blue"
                                placeholder="Last Name"
                                name="lastName"
                            />
                            <ErrorMessage
                                className="form-field-error hover:placeholder-bc-bright-blue"
                                name="lastName"
                                component="div"
                            />
                        </div>
                        <div className="col-span-2">
                            <InputWithSubmitButton
                                name="email"
                                type="email"
                                placeholder="Your Email"
                            />

                            <ErrorMessage
                                className="form-field-error"
                                name="email"
                                component="div"
                            />
                        </div>
                        <input type="submit" className="hidden" />
                    </Form>
                </Formik>
                {success && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="my-4 success-message text-center"
                    >
                        <p className="text-bc-bright-blue font-bold">
                            Thank you for subscribing!
                        </p>
                    </motion.div>
                )}
            </footer>
        </div>
    );
};

export default NewsletterSignup;
