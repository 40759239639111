import React, { ReactNode } from "react";
import { Colors } from "../../Types/Global";
import "./styles.scss";

interface SectionProps {
    children: ReactNode;
    backgroundColor?: Colors;
    borderColor?: Colors;
    staggeredBackgroundColor?: Colors; // creates the appearance of breaking out of the block
    gutter?: boolean;
}

const Section = ({
    children,
    backgroundColor = Colors.transparent,
    borderColor,
    staggeredBackgroundColor,
    gutter = true,
}: SectionProps) => {
    const styleClasses = [
        `bc-section`,
        gutter ? "my-4" : "",
        `bg-bc-${backgroundColor}`,
        borderColor
            ? `border-bc-${borderColor}`
            : `border-bc-${Colors.transparent}`,
        borderColor ? "border" : "",
        staggeredBackgroundColor ? `bc-staggered-background` : "",
    ].join(" ");

    return (
        <section className={`${styleClasses}`}>
            <div className="bc-section-content">{children}</div>
            <div
                className={`bc-section-staggered-background bg-bc-${
                    staggeredBackgroundColor ? staggeredBackgroundColor : ""
                }`}
            ></div>
        </section>
    );
};

export default Section;
