import {
    ReactNode,
    createContext,
    useContext,
    useState,
    useEffect,
} from "react";
import { useGetCompanyDetailsQuery } from "../generated/graphql";

export interface CompanyContacts {
    creative?: {
        name: string;
        email: string;
    };
    jobs?: {
        name: string;
        email: string;
    };
    newBusiness?: {
        name: string;
        email: string;
    };
    mediaAndPress?: {
        name: string;
        email: string;
    };
    mainContact?: {
        header: string;
        email: string;
    };
}

export interface CompanyDetailsState {
    loading: boolean;
    companyContacts?: CompanyContacts;
    footerLocations: {
        name: string;
    }[];
}

const CompanyDetailsContext = createContext<CompanyDetailsState>(
    undefined as any
);

export function useCompanyDetailsContext() {
    return useContext(CompanyDetailsContext);
}

export const CompanyDetailsProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [companyContacts, setCompanyContacts] = useState({});
    const [footerLocations, setFooterLocations] = useState<{ name: string }[]>(
        []
    );

    const { data, loading } = useGetCompanyDetailsQuery();

    useEffect(() => {
        if (data) {
            setCompanyContacts({
                creative: {
                    name: (
                        data.themeSettings?.companyContacts?.creative?.nodes.find(
                            () => true
                        ) as any
                    )?.title,
                    email: (
                        data.themeSettings?.companyContacts?.creative?.nodes.find(
                            () => true
                        ) as any
                    )?.employeeDetails?.email,
                },
                jobs: {
                    name: (
                        data.themeSettings?.companyContacts?.jobs?.nodes.find(
                            () => true
                        ) as any
                    )?.title,
                    email: (
                        data.themeSettings?.companyContacts?.jobs?.nodes.find(
                            () => true
                        ) as any
                    )?.employeeDetails?.email,
                },
                newBusiness: {
                    name: (
                        data.themeSettings?.companyContacts?.newBusiness?.nodes.find(
                            () => true
                        ) as any
                    )?.title,
                    email: (
                        data.themeSettings?.companyContacts?.newBusiness?.nodes.find(
                            () => true
                        ) as any
                    )?.employeeDetails?.email,
                },
                mediaAndPress: {
                    name: (
                        data.themeSettings?.companyContacts?.mediaAndPress?.nodes.find(
                            () => true
                        ) as any
                    )?.title,
                    email: (
                        data.themeSettings?.companyContacts?.mediaAndPress?.nodes.find(
                            () => true
                        ) as any
                    )?.employeeDetails?.email,
                },
                mainContact: {
                    header: (data.themeSettings?.companyContacts as any)
                        ?.contactUs?.header,
                    email: (data.themeSettings?.companyContacts as any)
                        ?.contactUs?.email,
                },
            });

            const locs = (
                data.themeSettings?.footerLocations?.locations?.nodes as any[]
            ).map((node: any) => {
                return { name: node.title };
            });

            setFooterLocations(locs);
        }
    }, [data]);

    return (
        <CompanyDetailsContext.Provider
            value={{
                companyContacts,
                footerLocations,
                loading,
            }}
        >
            {children}
        </CompanyDetailsContext.Provider>
    );
};
