import { AcfHomepageBanner } from "../../Data/dtos";
import Logo from "../../assets/images/tbcg-wide-logo-black.svg";
import { motion } from "framer-motion";
import BarGraphAnimation from "../BarGraphAnimation/BarGraphAnimation";
import "./styles.scss";

const HomePageBanner = (props: AcfHomepageBanner) => {
    if (!props) return null;
    const { backgroundColor, borderColor, content, image, text } = props;
    const backgroundColorClass = `bg-bc-${backgroundColor}`;

    return (
        <section className="homepage-banner">
            <div className="container mx-auto">
                <div className="banner-content grid grid-cols-12 grid-rows-auto gap-4">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2 }}
                        className={`homepage-banner-copy col-span-12 lg:col-span-9 rounded-lg ${backgroundColorClass} flex flex-col justify-center p-4 relative`}
                    >
                        <img
                            className="banner-logo"
                            src={Logo}
                            alt="The Barcode Group"
                            style={{ width: "13rem" }}
                        />
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.5 }}
                            className="banner-copy mt-12 md:mt-4 mb-10 md:mb-2 relative"
                            dangerouslySetInnerHTML={{ __html: content! }}
                        ></motion.div>

                        <BarGraphAnimation className="banner-anim w-full md:w-2/3" />
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3 }}
                        className="col-span-12 lg:col-span-3 flex flex-col justify-end min-h-xl"
                    >
                        <div
                            className={`banner-image-frame-wrapper rounded-lg overflow-hidden border-2 border-bc-${
                                borderColor || "soft-black"
                            } flex flex-col`}
                        >
                            <div
                                className="banner-image-frame h-full w-full rounded-lg flex flex-col justify-center items-center p-4"
                                style={{
                                    backgroundImage: `url(${image?.url})`,
                                }}
                            >
                                <h3 className="text-bc-soft-white">{text}</h3>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default HomePageBanner;
