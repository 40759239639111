import Button from "../Buttons/Button";
import FreshIcon from "../../assets/icons/fresh.svg";
import { NewsPost } from "../../Data/dtos";
import "./styles.scss";

const NewsPostPreview = (props: NewsPost) => {
    const { title, featuredImage, uri, fresh, excerpt, categories } = props;

    return (
        <li className="news-post-preview border-2 border-bc-soft-black rounded-lg col-span-12 lg:col-span-6 xl:col-span-4 overflow-hidden flex flex-col">
            {featuredImage?.url && (
                <header
                    className="post-image"
                    style={{ backgroundImage: `url(${featuredImage.url})` }}
                >
                    {fresh && (
                        <div className="fresh-tag">
                            <img src={FreshIcon} alt="fresh post" />
                        </div>
                    )}
                </header>
            )}
            <div className="post-content py-8 px-12 flex flex-col justify-between h-full">
                <div className="h-full pt-4">
                    <h4>{categories?.join(", ")}</h4>
                    <h2 className="serif mb-8 mt-6">{title}</h2>
                    <div
                        className="mt-12"
                        dangerouslySetInnerHTML={{ __html: excerpt || "" }}
                    ></div>
                </div>

                <footer className="mt-12">
                    <Button to={uri} text="Read on!" size="sm" />
                </footer>
            </div>
        </li>
    );
};

export default NewsPostPreview;
