import { useState, useEffect, forwardRef, useRef } from "react";
import { ServicesAccordionItem } from "../../Data/dtos";

interface AccordionItemProps extends ServicesAccordionItem {
    index: number;
    activeIndex: number;
    activeWidth: number;
    mobileLayout: boolean;
    onClick: (index: number) => void;
}

const AccordionItem = forwardRef<HTMLLIElement, AccordionItemProps>(
    (
        {
            index,
            activeIndex,
            backgroundColor,
            borderColor,
            image,
            icon,
            header,
            mobileLayout,
            content,
            sectionNumber,
            activeWidth,
            onClick,
        }: AccordionItemProps,
        ref
    ) => {
        const contentRef = useRef<HTMLDivElement>(null);

        const [activeHeight, setActiveHeight] = useState<string | number>(0);
        const [isActive, setIsActive] = useState(index === activeIndex);

        const getClassNames = () => {
            const whiteTextColors = ["soft-black", "navy", "salmon"];
            const classNames = [
                `bg-bc-${backgroundColor}`,
                `${
                    whiteTextColors.includes(backgroundColor || "")
                        ? "text-bc-soft-white"
                        : ""
                }`,
                `border-2 border-bc-${borderColor || "transparent"}`,
                `relative`,
            ].join(" ");

            return classNames;
        };

        const classNames = getClassNames();

        const handleClick = () => {
            onClick(index);
        };

        useEffect(() => {
            if (activeIndex === index) {
                setIsActive(true);
            } else {
                setIsActive(false);
            }
        }, [activeIndex]);

        useEffect(() => {
            if (mobileLayout) {
                if (contentRef.current) {
                    const height =
                        contentRef.current.getBoundingClientRect().height;
                    setActiveHeight(height);
                }
            } else {
                setActiveHeight("auto");
            }
        }, [mobileLayout, contentRef.current]);

        useEffect(() => {
            if (contentRef.current) {
                const height =
                    contentRef.current.getBoundingClientRect().height;
                setActiveHeight(height);
            }
        }, [contentRef.current]);

        return (
            <li
                ref={ref}
                className={[
                    classNames,
                    index === activeIndex ? "active" : "",
                ].join(" ")}
                onClick={handleClick}
                style={{
                    height: mobileLayout
                        ? isActive
                            ? activeHeight
                            : "6rem"
                        : "auto",
                }}
            >
                <span className="item-label absolute">{header}</span>
                <div className="item-preview">
                    <div className="item-label-preview-container">
                        <span className="item-label-preview">
                            <span>{header}</span>
                        </span>
                    </div>

                    <div className="preview-icon">
                        {icon && (
                            <img
                                className="item-icon-preview"
                                src={icon?.url || ""}
                                alt={icon?.altText || ""}
                            />
                        )}
                    </div>
                </div>
                <div
                    className="accordion-item-content"
                    ref={contentRef}
                    style={{ width: mobileLayout ? "auto" : activeWidth }}
                >
                    <div className="accordion-item-image">
                        <div
                            className="image-frame"
                            style={{
                                backgroundImage: `url(${image?.url})`,
                            }}
                        >
                            <div className="item-icon">
                                {icon && (
                                    <img
                                        src={icon?.url || ""}
                                        alt={icon?.altText || ""}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item-copy">
                        <header className="flex justify-between mb-8 lg:mb-2">
                            <p className="card-number">{sectionNumber}</p>
                            <p className="mobile-label">{header}</p>
                        </header>
                        <footer
                            dangerouslySetInnerHTML={{ __html: content! }}
                        ></footer>
                    </div>
                </div>
            </li>
        );
    }
);

export default AccordionItem;
