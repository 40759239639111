import { Link, LinkProps } from "react-router-dom";
import ArrowIcon from "../Arrow/Arrow";
import { HTMLProps } from "react";
import "./styles.scss";

type FancyButtonProps = Omit<HTMLProps<HTMLButtonElement>, "type"> &
    Omit<HTMLProps<HTMLAnchorElement>, "type"> & {
        text: string;
        to?: string;
        external?: boolean;
        type?: "button" | "link";
    };

const FancyButton = ({
    text,
    to,
    external,
    type,
    ...props
}: FancyButtonProps) => {
    const { ref, ...attrs } = props;
    const arrowWidth = "1.5rem";

    const classNames = ["fancy-button", props.className].join(" ");
    console.log("classNames", classNames, to);
    if (type === "link") {
        return (
            <Link
                to={to!}
                className={classNames}
                target={external ? "_blank" : ""}
                {...attrs}
            >
                <span>{text}</span>
                <ArrowIcon
                    width={arrowWidth}
                    strokeColorClass="stroke-bc-bright-blue"
                />
            </Link>
        );
    }

    return (
        <button className={classNames} {...props}>
            <span>{text}</span>
            <ArrowIcon
                width={arrowWidth}
                strokeColorClass="stroke-bc-bright-blue"
            />
        </button>
    );
};

export default FancyButton;
