import React, { ReactNode } from "react";
import { motion } from "framer-motion";

interface BasePageProps {
    children: ReactNode;
}

const BasePage = ({ children }: BasePageProps) => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {children}
        </motion.div>
    );
};

export default BasePage;
