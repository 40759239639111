import FooterNavigation from "../Navigation/Footer";
import Button from "../Buttons/Button";
import FancyButton from "../Buttons/FancyButton";
import logo from "../../assets/images/tbcg-logo-white.svg";
import { useModals, Modal } from "../../Providers/Modal";
import { useCompanyDetailsContext } from "../../Providers/CompanyDetails";
import { Link } from "react-router-dom";
const locations = [
    {
        name: "Bentonville",
        link: "/connect",
    },
    {
        name: "Chicago",
        link: "/connect",
    },
    {
        name: "Minneapolis",
        link: "/connect",
    },
    {
        name: "St. Louis",
        link: "/connect",
    },
    {
        name: "Seattle",
        link: "/connect",
    },
];

const Footer = () => {
    const Modals = useModals();
    const companyDetails = useCompanyDetailsContext();
    const { footerLocations } = companyDetails;

    const year = new Date().getFullYear();

    return (
        <footer className="bg-bc-soft-black text-white">
            <div className="container mx-auto pt-12 pb-4">
                <div className="w-full">
                    <div className="flex justify-end mb-2">
                        <Link to="/">
                            <img src={logo} />
                        </Link>
                    </div>
                </div>
                <div className="grid grid-cols-12 grid-rows-auto lg:gap-8">
                    <div className="col-span-12 lg:col-span-7">
                        <FooterNavigation />
                    </div>

                    <div className="col-span-12 lg:col-span-5">
                        <div className="mt-8">
                            <div className="mb-8">
                                <h3 className="text-white">Contact Us</h3>
                                <a
                                    href="mailto:info@thebarcodegroup.com"
                                    target="_blank"
                                >
                                    info@thebarcodegroup.com
                                </a>
                            </div>

                            <div className="border-t-2 border-bg-soft-white flex gap-4 pt-6 items-center flex-wrap lg:flex-nowrap">
                                <h5 className="w-1/8 flex-shrink-0">
                                    Follow Us
                                </h5>{" "}
                                <FancyButton
                                    type="button"
                                    text={"Newsletter"}
                                    onClick={() => {
                                        Modals.openModal(Modal.NEWSLETTER);
                                    }}
                                />{" "}
                                <FancyButton
                                    type="link"
                                    to="https://www.linkedin.com/company/the-barcode-group/"
                                    target="_blank"
                                    text={`LinkedIn`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="location-nav border-t py-8 mt-8">
                    <nav className="flex justify-around flex-wrap gap-8">
                        {footerLocations.map((loc, index) => (
                            <Button
                                key={`footer-loc-${index}`}
                                to={"/connect#locations"}
                                size="sm"
                                text={loc.name}
                                arrowStrokeClass="stroke-bc-soft-white"
                                arrowStrokeHoverClass="stroke-bc-chartreuse"
                            />
                        ))}
                    </nav>
                </div>
                <div className="mt-8">
                    <p className="text-white serif font-thin text-sm">
                        All Rights Reserved |{" "}
                        <span className="mr-1">&copy;</span>
                        {year} The Barcode Group, Inc.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
