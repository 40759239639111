import { AcfPromotedTeamMembers } from "../../Data/dtos";
import { Link } from "react-router-dom";
import Section from "../Section/Section";
import "./styles.scss";

const PromotedTeamMembers = ({
    header,
    bodyText,
    teamMembers,
}: AcfPromotedTeamMembers) => {
    return (
        <Section>
            <div className="container mx-auto py-4 px-4">
                <header className="flex flex-col gap-8 mb-12">
                    <h1>{header}</h1>
                    <p>{bodyText}</p>
                </header>

                <div>
                    <ul className="promoted-employees-list grid grid-cols-12 gap-6">
                        {teamMembers?.map((member: any, index) => (
                            <li
                                key={`promoted-employee-${index}`}
                                className="col-span-6 md:col-span-4 lg:col-span-2"
                            >
                                <div
                                    className="promoted-member-headshot rounded-lg border border-bc-bright-blue"
                                    style={{
                                        backgroundImage: `url(${member.image?.url})`,
                                    }}
                                    title={member.image?.altText}
                                ></div>
                                <div>
                                    <p className="pb-2 mb-4 border-b border-bc-soft-black">
                                        {member.name}
                                    </p>
                                    <p className="text-sm mb-1">
                                        {member.title}
                                    </p>
                                    {member.locations?.length > 0 && (
                                        <p className="text-sm mb-2">
                                            {member.locations[0]}
                                        </p>
                                    )}
                                    <Link
                                        className="mail-link"
                                        to={`mailto:${member?.email}`}
                                        target="_blank"
                                    >
                                        <svg
                                            className="inline"
                                            width="40"
                                            height="40"
                                            viewBox="0 0 40 40"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                className="envelope-flap"
                                                d="M5.00377 6.5L14.5718 16.0672C15.3601 16.8554 16.6408 16.847 17.4186 16.0484L26.719 6.5"
                                                stroke="#2885FF"
                                                strokeWidth="3"
                                            />
                                            <path
                                                className="envelope-crease"
                                                d="M5.00377 6.5L14.5718 16.0672C15.3601 16.8554 16.6408 16.847 17.4186 16.0484L26.719 6.5"
                                                stroke="#2885FF"
                                                strokeWidth="3"
                                            />
                                            <rect
                                                x="2"
                                                y="9"
                                                width="28"
                                                height="19"
                                                rx="2"
                                                stroke="#2885FF"
                                                strokeWidth="3"
                                            />
                                        </svg>
                                    </Link>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Section>
    );
};

export default PromotedTeamMembers;
