import { useRef, useState, useEffect } from "react";
import useOnScreen from "../../Hooks/IsVisible";
import Button from "../Buttons/Button";
import { AcfHomepageCta } from "../../Data/dtos";
import HelloSticker from "../../assets/icons/hello-sticker.svg";
import MeetSticker from "../../assets/icons/please-to-meet-you-sticker.svg";
import DataUtilities from "../../Data/Utilities";
import "./styles.scss";

const MeetUs = (props: AcfHomepageCta) => {
    const [animationTriggered, setAnimationTriggered] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref, { threshold: 1 });

    const { sectionNumber, header, image, link } = props;

    useEffect(() => {
        if (animationTriggered) return;

        if (isVisible) {
            setAnimationTriggered(true);
        }
    }, [isVisible]);

    return (
        <div
            ref={ref}
            className={`meet-us container mx-auto py-4 md:py-12 mb-12 ${
                animationTriggered ? "in-view" : ""
            }`}
        >
            <div className="meet-us-content relative border-2 border-bc-soft-black rounded-lg flex flex-col md:flex-row justify-between p-8 md:p-14 items-start md:items-center overflow-hidden">
                <div
                    className="absolute top-0 left-0 w-full h-full"
                    style={{ zIndex: "-1" }}
                >
                    <img
                        src={HelloSticker}
                        alt="Hello Sticker"
                        className="hello-sticker absolute top-0 right-0 left-0 bottom-0 m-auto"
                        style={{
                            zIndex: "-1",
                            width: "150px",
                            filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.075))",
                        }}
                    />
                    <img
                        src={MeetSticker}
                        alt="Please to meet you sticker"
                        className="meet-sticker absolute top-0 right-0 left-0 bottom-0 m-auto"
                        style={{
                            zIndex: "-1",
                            width: "120px",
                            filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.075))",
                        }}
                    />
                </div>

                <p className="card-number mb-8 md:mb-0">
                    {parseFloat(sectionNumber).toFixed(1)}
                </p>

                <h3 className="flex items-center">
                    <img
                        src={image?.url}
                        alt={image?.altText}
                        className="mr-4 talkie-icon"
                    />{" "}
                    Meet Us!
                </h3>
                <Button
                    size="sm"
                    to={
                        link?.url
                            ? DataUtilities.localizeUrl(link?.url)
                            : "/connect"
                    }
                    text={link?.title || "Learn more"}
                />
            </div>
        </div>
    );
};

export default MeetUs;
