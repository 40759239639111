import { ReactNode } from "react";
import { motion } from "framer-motion";
import "./styles.scss";
import { Colors } from "../../Types/Global";

interface ModalProps {
    open: boolean;
    children: ReactNode;
    border?: boolean;
    borderColor?: Colors;
    onClose?: () => void;
}

const Modal = ({
    open,
    children,
    border = false,
    borderColor = Colors["soft-black"],
    onClose,
}: ModalProps) => {
    if (!open) return null;
    return (
        <motion.div
            className="bc-modal-blockout"
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            onClick={() => {
                onClose && onClose();
            }}
        >
            <motion.div
                className={[
                    "bc-modal",
                    border ? "border border-2" : "",
                    `border-bc-${borderColor}`,
                    "px-4 py-8 lg:px-14 lg:py-16",
                ].join(" ")}
                animate={{ scale: 1, opacity: 1 }}
                initial={{ scale: 0.8, opacity: 0 }}
                exit={{ scale: 0.8, opacity: 0 }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className="modal-content relative">
                    <span
                        className="close-btn"
                        onClick={() => {
                            onClose && onClose();
                        }}
                    ></span>
                    {children}
                </div>
            </motion.div>
        </motion.div>
    );
};

export default Modal;
