import { useState } from "react";
import Button from "../Buttons/Button";
import { AcfServiceCards } from "../../Data/dtos";
import ExternalButton from "../Buttons/External";
import LineGraphAnimation from "../LineGraphAnimation/LineGraphAnimation";
import ImageRotator from "../ImageRotator/ImageRotator";

const ServicesImageGrid = (props: AcfServiceCards) => {
    const [pauseIndex, setPauseIndex] = useState<number | null>(null);
    const { dashboard, intelligence, production } = props;

    if (!intelligence || !dashboard || !production) return null;
    return (
        <div className="container mx-auto">
            <div className="grid grid-cols-12 grid-rows-auto gap-4">
                <div
                    className="order-1 col-span-12 md:col-span-6 row-span-2 lg:col-span-4 bg-bc-bright-blue rounded-lg p-6 lg:p-8 xl:p-12"
                    onMouseOver={() => {
                        setPauseIndex(0);
                    }}
                    onMouseLeave={() => {
                        setPauseIndex(null);
                    }}
                >
                    <header className="flex">
                        <p className="card-number mr-4">
                            {intelligence.sectionNumber}
                        </p>
                        <p className="super-title text-bc-soft-white">
                            {intelligence.superTitle}
                        </p>
                    </header>
                    <div className="text-bc-soft-white py-4">
                        <h1 className="my-4 text-bc-soft-white">
                            {intelligence.header}
                        </h1>
                        <div
                            className="mb-4 mt-2 text-bc-soft-white"
                            dangerouslySetInnerHTML={{
                                __html: intelligence.content!,
                            }}
                        ></div>
                    </div>
                </div>
                <div
                    className="order-2 col-span-12 row-span-2 md:col-span-6 lg:col-span-4 border-2 border-bc-salmon rounded-lg p-6 lg:p-8 xl:p-12"
                    onMouseOver={() => {
                        setPauseIndex(1);
                    }}
                    onMouseLeave={() => {
                        setPauseIndex(null);
                    }}
                >
                    <header className="flex">
                        <p className="card-number mr-4">
                            {dashboard.sectionNumber}
                        </p>
                        <p className="super-title text-bc-soft-black">
                            {dashboard.superTitle}
                        </p>
                    </header>
                    <div className="flex flex-col justify-between">
                        <div className="py-4">
                            <h1 className="my-4 text-bc-salmon">
                                {dashboard.header}
                            </h1>
                            <div
                                className="mb-4 mt-2 text-bc-soft-black"
                                dangerouslySetInnerHTML={{
                                    __html: dashboard.content!,
                                }}
                            ></div>
                        </div>
                        <LineGraphAnimation />
                        <footer className="mt-12 flex flex-col gap-2">
                            <p>Access dashboards</p>
                            <div className="grid grid-cols-2 gap-4">
                                {dashboard?.link && (
                                    <div className="col-span-1">
                                        <ExternalButton
                                            to={dashboard?.link?.url}
                                            text={dashboard?.link?.title}
                                        />
                                    </div>
                                )}
                                {dashboard.link2 && (
                                    <div className="col-span-1">
                                        <ExternalButton
                                            to={dashboard?.link2?.url}
                                            text={dashboard?.link2?.title}
                                        />
                                    </div>
                                )}
                            </div>
                        </footer>
                    </div>
                </div>
                <div
                    className="order-4 lg:order-3 col-span-12 row-span-3 lg:col-span-4 bg-bc-light-blue rounded-lg relative overflow-hidden"
                    style={{ minHeight: 420 }}
                >
                    <ImageRotator
                        pauseIndex={pauseIndex}
                        images={[
                            intelligence.image.url,
                            dashboard.image.url,
                            production.image.url,
                        ]}
                    />
                </div>
                <div
                    className="order-3 lg:order-4 col-span-12 lg:col-span-8 bg-bc-chartreuse rounded-lg p-6 lg:p-8 xl:p-12 flex"
                    onMouseOver={() => {
                        setPauseIndex(2);
                    }}
                    onMouseLeave={() => {
                        setPauseIndex(null);
                    }}
                >
                    <div className="w-1/2 pb-8">
                        <header className="flex mb-8">
                            <p className="card-number mr-4">1.5</p>
                            <p className="super-title text-bc-soft-black">
                                {production.superTitle}
                            </p>
                        </header>
                        <h1>{production.header}</h1>
                    </div>
                    <div
                        className="w-1/2 py-8"
                        dangerouslySetInnerHTML={{
                            __html: production.content!,
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default ServicesImageGrid;
