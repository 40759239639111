import logo from "../../assets/images/tbcg-wide-logo.svg";
import GlobalNavigation from "../Navigation/Global";
import { useLocation, Location } from "react-router-dom";
import Luminate from "./Luminate";
import "./styles.scss";

const Header = ({ location }: { location: Location }) => {
    const isHome = location.pathname === "/";
    const showLuminate = isHome;

    return (
        <>
            {isHome && <Luminate />}
            <header
                className={`header ${
                    showLuminate ? "luminate-banner-showing" : ""
                }`}
            >
                <div className="container mx-auto relative mt-3">
                    <div className="flex justify-between items-center">
                        <GlobalNavigation />
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
