import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";

const httpLink = new HttpLink({
    uri: `${
        process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_API_URL
            : ""
    }/graphql`,
});

const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
});

export default client;
