import {
    PageContentBlocks,
    AcfHomepageBanner,
    AcfCenteredText,
    AcfNews,
    AcfTwoColumnText,
    AcfHomepageCardsLower,
    AcfHomepageUpperCards,
    AcfHomepageCta,
    AcfBanner,
    AcfServicesAccordion,
    AcfVerticalAccordion,
    AcfLegacyBanner,
    AcfStatsBanner,
    AcfLogoTicker,
    AcfServiceCards,
    AcfServiceCta,
    AcfServiceColumns,
    AcfAboutCta,
    AcfConnectContacts,
    AcfConnectLocations,
    AcfConnectCta,
    AcfConnectEmployees,
    CompanyLocationData,
    AcfResourcesBanner,
    AcfResources,
    AcfShoppingMapBanner,
    AcfShoppingMaps,
    AcfShoppingMapCta,
    AcfCoreValues,
    AcfCompanyBenefits,
    AcfJoinTeam,
    AcfPromotedTeamMembers,
    AcfImageCarousel,
    NewsPost,
} from "./dtos";
import {
    GetLocationsQuery,
    NewsPostFragmentFragment,
} from "../generated/graphql";
import { animate } from "framer-motion";

class DataUtilities {
    public static getEditorBlockByName<T>(
        name: string,
        editorBlocks: any[]
    ): T | undefined {
        return editorBlocks?.find(
            (block: { __typename: string }) => block.__typename === name
        );
    }

    public static getEditorBlocksByName<T>(
        name: string,
        editorBlocks: any[]
    ): T[] | undefined {
        return editorBlocks?.filter(
            (block: { __typename: string }) => block.__typename === name
        );
    }

    public static getCookie(name: string): string | null {
        const nameEQ = name + "=";
        const ca = document.cookie.split(";"); // Split cookie string into an array
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") c = c.substring(1, c.length); // Trim leading whitespace
            if (c.indexOf(nameEQ) === 0)
                return c.substring(nameEQ.length, c.length); // Check if the cookie's name matches and return the value
        }
        return null; // Return null if not found
    }

    public static flattenLocationData(
        locationData: GetLocationsQuery
    ): CompanyLocationData[] | undefined {
        return locationData?.locations?.nodes?.map((location: any) => {
            return {
                name: location?.title,
                address: location?.locationDetails?.address,
                contactEmail: location?.locationDetails?.contactEmail,
                googleLink: location?.locationDetails?.googleLink,
            };
        });
    }

    public static localizeUrl(url: string): string {
        //temp url structure
        if (url.includes("/cms/")) {
            return url.replace("/cms/", "/").replace(/.*?\/\/.*?\//, "/");
        }

        if (url.includes("https://cms.barcode.websitetestlink.dev")) {
            return url.replace("https://cms.barcode.websitetestlink.dev", "");
        }

        // if the string is a URI already, don't do anything.
        if (url.startsWith("/")) {
            return url;
        }
        // otherwise replace everything up to the first slash with nothing.
        return url.replace(/.*?\/\/.*?\//, "/");
    }

    public static flattenNewsPostData(
        post?: NewsPostFragmentFragment | null
    ): NewsPost {
        if (!post) {
            return {} as NewsPost;
        }
        return {
            id: post?.id || "",
            title: post?.title || "",
            date: post?.date ? new Date(post.date) : new Date(),
            content: post?.content || "",
            excerpt: post?.excerpt || "",
            featuredImage: post?.featuredImage?.node?.mediaItemUrl
                ? {
                      url: post?.featuredImage?.node.mediaItemUrl,
                      altText: post?.featuredImage.node.altText || "",
                  }
                : undefined,
            uri: post?.uri || "",
            categories: post?.categories?.nodes?.map((node: any) => node.name),
            tags: post?.tags?.nodes?.map((node: any) => node.name),
            fresh: post?.postDetails?.fresh || undefined,
            promoted: post?.postDetails?.promoted || undefined,
        };
    }

    public static flattenEditorBlocks(
        editorBlocks: any[]
    ): (PageContentBlocks | { name: string; error: string })[] {
        return editorBlocks?.map((block: any) => {
            const blockName = block.__typename;

            switch (blockName) {
                case "AcfCoreValues":
                    return {
                        name: block.name,
                        header: block.coreValues?.header,
                        superTitle: block.coreValues?.superTitle,
                        bodyText: block.coreValues?.bodyText,
                        coreValues: block.coreValues?.coreValue?.map(
                            (value: any) => {
                                return {
                                    label: value?.label,
                                    valueText: value?.valueText,
                                };
                            }
                        ),
                    } as AcfCoreValues;
                case "AcfPromotedTeamMembers":
                    return {
                        name: block.name,
                        header: block.promotedTeamMembers?.header,
                        bodyText: block.promotedTeamMembers?.bodyText,
                        teamMembers:
                            block.promotedTeamMembers?.teamMembers?.map(
                                (member: any) => ({
                                    name: member?.team?.nodes.find(() => true)
                                        ?.title,
                                    title: member?.team?.nodes.find(() => true)
                                        ?.employeeDetails?.title,
                                    email: member?.team?.nodes.find(() => true)
                                        ?.employeeDetails?.email,
                                    image: {
                                        url: member?.team?.nodes.find(
                                            () => true
                                        )?.employeeDetails?.headshot?.node
                                            ?.mediaItemUrl,
                                        altText: member?.team?.nodes.find(
                                            () => true
                                        )?.employeeDetails?.headshot?.node
                                            ?.altText,
                                    },
                                    locations: member?.team?.nodes
                                        .find(() => true)
                                        ?.employeeDetails?.location?.nodes?.map(
                                            (loc: any) => loc?.title
                                        ),
                                    retailers: [],
                                })
                            ),
                    } as AcfPromotedTeamMembers;
                case "AcfCompanyBenefits":
                    return {
                        name: block.name,
                        header: block.companyBenefits?.header,
                        image: {
                            url: block.companyBenefits?.image?.node
                                ?.mediaItemUrl,
                            altText:
                                block.companyBenefits?.image?.node?.altText,
                        },
                        content: block.companyBenefits?.content?.map(
                            (benefit: any) => ({
                                title: benefit?.title,
                                text: benefit?.text,
                            })
                        ),
                    } as AcfCompanyBenefits;
                case "AcfImageCarousel":
                    return {
                        name: block.name,
                        images: block.imageCarousel?.images?.map(
                            (image: any) => {
                                return {
                                    url: image?.carouselImage?.node
                                        ?.mediaItemUrl,
                                    altText:
                                        image?.carouselImage?.node?.altText,
                                };
                            }
                        ),
                    } as AcfImageCarousel;
                case "AcfJoinTeam":
                    return {
                        name: block.name,
                        header: block.joinOurTeam?.header,
                        bodyText: block.joinOurTeam?.bodyText,
                        contactType: block.joinOurTeam?.contactType,
                        boxColor: block.joinOurTeam?.boxColor,
                        ctaLink: {
                            url: block.joinOurTeam?.ctaLink?.url,
                            title: block.joinOurTeam?.ctaLink?.title,
                            target: block.joinOurTeam?.ctaLink?.target,
                        },
                        email: block.joinOurTeam?.email,
                    } as AcfJoinTeam;
                case "AcfHomepageBanner":
                    return {
                        name: block.name,
                        content: block.homepageBanner?.content,
                        image: {
                            url: block.homepageBanner?.image?.node
                                ?.mediaItemUrl,
                            altText: block.homepageBanner?.image?.node?.altText,
                        },
                        text: block.homepageBanner?.text,
                        backgroundColor:
                            block.homepageBanner?.colors?.background?.find(
                                () => true
                            ),
                        borderColor: block.homepageBanner?.colors?.border?.find(
                            () => true
                        ),
                    } as AcfHomepageBanner;
                case "AcfHomepageCardsLower":
                    return {
                        name: block.name,
                        news: {
                            header: block.homepageCardsLower?.news?.header,
                            sectionNumber:
                                block.homepageCardsLower?.news?.sectionNumber,
                            subhead: block.homepageCardsLower?.news?.subhead,
                            link: {
                                target: block.homepageCardsLower?.news?.link
                                    ?.target,
                                url: block.homepageCardsLower?.news?.link?.url,
                                title: block.homepageCardsLower?.news?.link
                                    ?.title,
                            },
                            featuredNews:
                                block.homepageCardsLower?.news?.featuredNews?.nodes?.map(
                                    (newsPost: any) => {
                                        return {
                                            id: newsPost?.id,
                                            title: newsPost?.title,
                                            image: {
                                                altText:
                                                    newsPost?.featuredImage
                                                        ?.node?.altText,
                                                url: newsPost?.featuredImage
                                                    ?.node?.mediaItemUrl,
                                            },
                                            uri: newsPost?.uri,
                                            fresh: newsPost?.postDetails?.fresh,
                                        };
                                    }
                                ),
                            otherNews:
                                block.homepageCardsLower?.news?.otherNews?.nodes?.map(
                                    (newsPost: any) => {
                                        return {
                                            id: newsPost?.id,
                                            title: newsPost?.title,
                                            uri: newsPost?.uri,
                                            fresh: newsPost?.postDetails?.fresh,
                                        };
                                    }
                                ),
                        },
                        shop: {
                            text: block.homepageCardsLower?.shop?.text,
                            sectionNumber:
                                block.homepageCardsLower?.shop?.sectionNumber,
                            link: {
                                target: block.homepageCardsLower?.shop?.link
                                    ?.target,
                                url: block.homepageCardsLower?.shop?.link?.url,
                                title: block.homepageCardsLower?.shop?.link
                                    ?.title,
                            },
                        },
                        map: {
                            link: {
                                target: block.homepageCardsLower?.map?.link
                                    ?.target,
                                url: block.homepageCardsLower?.map?.link?.url,
                                title: block.homepageCardsLower?.map?.link
                                    ?.title,
                            },
                            image: {
                                altText:
                                    block.homepageCardsLower?.map?.image?.node
                                        ?.altText,
                                url: block.homepageCardsLower?.map?.image?.node
                                    ?.mediaItemUrl,
                            },
                        },
                        reports: {
                            content: block.homepageCardsLower?.reports?.content,
                            header: block.homepageCardsLower?.reports?.header,
                            linkText:
                                block.homepageCardsLower?.reports?.linkText,
                            sectionNumber:
                                block.homepageCardsLower?.reports
                                    ?.sectionNumber,
                            title: block.homepageCardsLower?.reports?.title,
                            buttonText:
                                block.homepageCardsLower?.reports?.button_text,
                        },
                    } as AcfHomepageCardsLower;
                case "AcfHomepageCta":
                    return {
                        name: block.name,
                        header: block.homepageCta?.header,
                        sectionNumber: block.homepageCta?.sectionNumber,
                        image: {
                            url: block.homepageCta?.image?.node?.mediaItemUrl,
                            altText: block.homepageCta?.image?.node?.altText,
                        },
                        link: {
                            target: block.homepageCta?.link?.target,
                            url: block.homepageCta?.link?.url,
                            title: block.homepageCta?.link?.title,
                        },
                    } as AcfHomepageCta;
                case "AcfBanner":
                    return {
                        name: block.name,
                        content: block.banner?.content,
                        image: {
                            url: block.banner?.image?.node?.mediaItemUrl,
                            altText: block.banner?.image?.node?.altText,
                        },
                        imageLocation: block.banner?.imageLocation?.find(
                            () => true
                        ),
                        backgroundColor: block.banner?.colors?.background?.find(
                            () => true
                        ),
                        borderColor: block.banner?.colors?.border?.find(
                            () => true
                        ),
                    } as AcfBanner;
                case "AcfServicesAccordion":
                    const blockData = block.servicesAccordion;
                    return {
                        name: block.name,
                        bottomText: {
                            header: blockData?.bottomText?.header,
                            linkSeparator:
                                blockData?.bottomText?.linkJoiningText || "",
                            links: blockData?.bottomText?.links?.map(
                                (link: any) => {
                                    return {
                                        target: link?.link?.target,
                                        url: link?.link?.url,
                                        title: link?.link?.title,
                                    };
                                }
                            ),
                        },
                        accordion: blockData?.accordion?.map(
                            (accordionItem: any) => {
                                return {
                                    content: accordionItem?.content,
                                    header: accordionItem?.header,
                                    backgroundColor:
                                        accordionItem?.colors?.background?.find(
                                            () => true
                                        ),
                                    borderColor:
                                        accordionItem?.colors?.border?.find(
                                            () => true
                                        ),
                                    icon: {
                                        altText:
                                            accordionItem?.icon?.node?.altText,
                                        url: accordionItem?.icon?.node
                                            ?.mediaItemUrl,
                                    },
                                    image: {
                                        altText:
                                            accordionItem?.image?.node?.altText,
                                        url: accordionItem?.image?.node
                                            ?.mediaItemUrl,
                                    },
                                    sectionNumber: accordionItem?.sectionNumber,
                                };
                            }
                        ),
                    } as AcfServicesAccordion;
                case "AcfVerticalAccordion":
                    return {
                        name: block.name,
                        sectionNumber: block.verticalAccordion?.sectionNumber,
                        header: block.verticalAccordion?.header,
                        accordion: block.verticalAccordion?.content?.map(
                            (accordionItem: any) => {
                                return {
                                    title: accordionItem?.title,
                                    text: accordionItem?.text,
                                };
                            }
                        ),
                    } as AcfVerticalAccordion;
                case "AcfCenteredText":
                    return {
                        name: block.name,
                        superTitle: block.centeredText?.superTitle,
                        content: block.centeredText?.content,
                    } as AcfCenteredText;
                case "AcfNews":
                    return {
                        name: block.name,
                        header: block.news?.header,
                        link: {
                            target: block.news?.link?.target,
                            url: block.news?.link?.url,
                            title: block.news?.link?.title,
                        },
                        news: block.news?.news?.nodes?.map((newsItem: any) => {
                            return {
                                id: newsItem?.id,
                                title: newsItem?.title,
                                text: newsItem?.excerpt,
                                date: newsItem?.date,
                                image: {
                                    altText:
                                        newsItem?.featuredImage?.node?.altText,
                                    url: newsItem?.featuredImage?.node
                                        ?.mediaItemUrl,
                                },
                                categories: newsItem?.categories?.nodes?.map(
                                    (cat: any) => cat.name
                                ),
                                tags: newsItem?.tags?.nodes?.map(
                                    (tag: any) => tag.name
                                ),
                                uri: newsItem?.uri,
                                fresh: newsItem?.postDetails?.fresh,
                            };
                        }),
                    } as AcfNews;
                case "AcfTwoColumnText":
                    return {
                        name: block.name,
                        sectionNumber: block.twoColumnText?.sectionNumber,
                        textLeft: block.twoColumnText?.textLeft,
                        textRight: block.twoColumnText?.textRight,
                        backgroundColor:
                            block.twoColumnText?.colors?.background?.find(
                                () => true
                            ),
                        borderColor: block.twoColumnText?.colors?.border?.find(
                            () => true
                        ),
                    } as AcfTwoColumnText;
                case "AcfLegacyBanner":
                    return {
                        name: block.name,
                        content: block.legacyBanner?.content,
                        logos: block.legacyBanner?.logos?.nodes?.map(
                            (logo: any) => {
                                return {
                                    url: logo?.mediaItemUrl,
                                    altText: logo?.altText,
                                };
                            }
                        ),
                    } as AcfLegacyBanner;
                case "AcfStatsBanner":
                    return {
                        name: block.name,
                        content: block.statsBanner?.content,
                        stats: block.statsBanner?.stats?.map((stat: any) => {
                            return {
                                stat: stat?.stat,
                                statSuffix: stat?.statSuffix,
                            };
                        }),
                    } as AcfStatsBanner;
                case "AcfLogoTicker":
                    return {
                        name: block.name,
                        logos: block.logoTicker?.retailers?.nodes?.map(
                            (retailer: any) => {
                                return {
                                    altText:
                                        retailer?.retailerDetails?.logo?.node
                                            ?.altText || "",
                                    url:
                                        retailer?.retailerDetails?.logo?.node
                                            ?.mediaItemUrl || "",
                                };
                            }
                        ),
                    } as AcfLogoTicker;
                case "AcfHomepageUpperCards":
                    return {
                        name: block.name,
                        dashboard: {
                            header: block.homepageCardsUpper?.dashboard?.header,
                            content:
                                block.homepageCardsUpper?.dashboard?.content,
                            superTitle:
                                block.homepageCardsUpper?.dashboard?.superTitle,
                            link: {
                                target: block.homepageCardsUpper?.dashboard
                                    ?.link?.target,
                                url: block.homepageCardsUpper?.dashboard?.link?.url?.trim(),
                                title: block.homepageCardsUpper?.dashboard?.link
                                    ?.title,
                            },
                            link2: {
                                target: block.homepageCardsUpper?.dashboard
                                    ?.link2?.target,
                                url: block.homepageCardsUpper?.dashboard?.link2?.url?.trim(),
                                title: block.homepageCardsUpper?.dashboard
                                    ?.link2?.title,
                            },
                        },
                        header: {
                            text: block.homepageCardsUpper?.header?.text,
                        },
                        locations: {
                            header: block.homepageCardsUpper?.locations?.header,
                            link: {
                                target: block.homepageCardsUpper?.locations
                                    ?.link?.target,
                                url: block.homepageCardsUpper?.locations?.link
                                    ?.url,
                                title: block.homepageCardsUpper?.locations?.link
                                    ?.title,
                            },
                            locations:
                                block.homepageCardsUpper?.locations?.locations?.nodes?.map(
                                    (location: any) => {
                                        return {
                                            title: location?.title,
                                        };
                                    }
                                ),
                        },
                        partners: {
                            header: block.homepageCardsUpper?.partners?.header,
                            retailers:
                                block.homepageCardsUpper?.partners?.retailers?.nodes?.map(
                                    (retailer: any) => {
                                        return {
                                            logo: {
                                                url: retailer?.retailerDetails
                                                    ?.logo?.node?.mediaItemUrl,
                                                altText:
                                                    retailer?.retailerDetails
                                                        ?.logo?.node?.altText,
                                            },
                                        };
                                    }
                                ),
                        },
                    } as AcfHomepageUpperCards;
                case "AcfServiceCards":
                    return {
                        name: block.name,
                        dashboard: {
                            content: block.serviceCards?.dashboard?.content,
                            header: block.serviceCards?.dashboard?.header,
                            image: {
                                url: block.serviceCards?.dashboard?.hoverImage
                                    ?.node?.mediaItemUrl,
                                altText:
                                    block.serviceCards?.dashboard?.hoverImage
                                        ?.node?.altText,
                            },
                            sectionNumber:
                                block.serviceCards?.dashboard?.sectionNumber,
                            superTitle:
                                block.serviceCards?.dashboard?.superTitle,
                            link: {
                                target: block.serviceCards?.dashboard?.link
                                    ?.target,
                                url: block.serviceCards?.dashboard?.link?.url,
                                title: block.serviceCards?.dashboard?.link
                                    ?.title,
                            },
                            link2: block.serviceCards?.dashboard?.link2
                                ? {
                                      target: block.serviceCards?.dashboard
                                          ?.link2?.target,
                                      url: block.serviceCards?.dashboard?.link2
                                          ?.url,
                                      title: block.serviceCards?.dashboard
                                          ?.link2?.title,
                                  }
                                : null,
                        },
                        intelligence: {
                            content: block.serviceCards?.intelligence?.content,
                            header: block.serviceCards?.intelligence?.header,
                            sectionNumber:
                                block.serviceCards?.intelligence?.sectionNumber,
                            image: {
                                url: block.serviceCards?.intelligence
                                    ?.hoverImage?.node?.mediaItemUrl,
                                altText:
                                    block.serviceCards?.intelligence?.hoverImage
                                        ?.node?.altText,
                            },

                            superTitle:
                                block.serviceCards?.intelligence?.superTitle,
                        },
                        production: {
                            content: block.serviceCards?.production?.content,
                            header: block.serviceCards?.production?.header,
                            image: {
                                url: block.serviceCards?.production?.hoverImage
                                    ?.node?.mediaItemUrl,
                                altText:
                                    block.serviceCards?.production?.hoverImage
                                        ?.node?.altText,
                            },
                            sectionNumber:
                                block.serviceCards?.production?.sectionNumber,
                            superTitle:
                                block.serviceCards?.production?.superTitle,
                        },
                    } as AcfServiceCards;
                case "AcfServiceColumns":
                    return {
                        name: block.name,
                        header: block.serviceColumns?.header,
                        sectionNumber: block.serviceColumns?.sectionNumber,
                        icon: {
                            url: block.serviceColumns?.icon?.node?.mediaItemUrl,
                            altText: block.serviceColumns?.icon?.node?.altText,
                        },
                        columns: block.serviceColumns?.servicesColumns?.map(
                            (column: any) => {
                                return {
                                    sectionNumber: column?.sectionNumber,
                                    header: column?.header,
                                    content: column?.content,
                                    icon: {
                                        url: column?.icon?.node?.mediaItemUrl,
                                        altText: column?.icon?.node?.altText,
                                    },
                                    image: {
                                        url: column?.image?.node?.mediaItemUrl,
                                        altText: column?.image?.node?.altText,
                                    },
                                };
                            }
                        ),
                    } as AcfServiceColumns;
                case "AcfServiceCta":
                    return {
                        name: block.name,
                        header: block.serviceCta?.header,
                        contactSuper: block.serviceCta?.contactSuper,
                        contactHeader: block.serviceCta?.contactHeader,
                        content: block.serviceCta?.content,
                        image: {
                            url: block.serviceCta?.contactIcon?.node
                                ?.mediaItemUrl,
                            altText:
                                block.serviceCta?.contactIcon?.node?.altText,
                        },
                        link: {
                            target: block.serviceCta?.contactLink?.target,
                            url: block.serviceCta?.contactLink?.url,
                            title: block.serviceCta?.contactLink?.title,
                        },
                    } as AcfServiceCta;
                case "AcfAboutCta":
                    return {
                        name: block.name,
                        header: block.aboutCta?.header,
                        reports: {
                            header: block.aboutCta?.reports?.header,
                            superTitle: block.aboutCta?.reports?.superTitle,
                            content: block.aboutCta?.reports?.content,
                            accessText: block.aboutCta?.reports?.accessText,
                        },
                        newsletter: {
                            header: block.aboutCta?.newsletter?.header,
                            content: block.aboutCta?.newsletter?.content,
                        },
                    } as AcfAboutCta;
                case "AcfConnectContacts":
                    return {
                        name: block.name,
                        content: block.connectContacts?.content,
                        header: block.connectContacts?.header,
                    } as AcfConnectContacts;
                case "AcfConnectLocations":
                    return {
                        name: block.name,
                        content: block.connectLocations?.content,
                        header: block.connectLocations?.header,
                        superTitle: block.connectLocations?.superTitle,
                    } as AcfConnectLocations;
                case "AcfConnectCta":
                    return {
                        name: block.name,
                        content: block.connectCta?.content,
                        header: block.connectCta?.header,
                        link: {
                            target: block.connectCta?.link?.target,
                            url: block.connectCta?.link?.url,
                            title: block.connectCta?.link?.title,
                        },
                    } as AcfConnectCta;
                case "AcfConnectEmployees":
                    return {
                        name: block.name,
                        content: block.connectEmployees?.content,
                        header: block.connectEmployees?.header,
                        link: {
                            target: block.connectEmployees?.link?.target,
                            url: block.connectEmployees?.link?.url,
                            title: block.connectEmployees?.link?.title,
                        },
                    } as AcfConnectEmployees;
                case "AcfResourcesBanner":
                    const featuredResource =
                        block.resourcesBanner?.featuredResource?.nodes?.find(
                            () => true
                        );
                    return {
                        name: block.name,
                        featuredResource: {
                            content: featuredResource?.content,
                            categories:
                                featuredResource?.resourceDetails?.resourceCategory?.nodes?.map(
                                    (cat: any) => cat.name
                                ),
                            title: featuredResource?.title,
                            image: {
                                url: featuredResource?.resourceDetails
                                    ?.resourceImage?.node?.mediaItemUrl,
                                altText:
                                    featuredResource?.resourceDetails
                                        ?.resourceImage?.node?.mediaItemUrl,
                            },
                            // file: {
                            //     url: featuredResource?.resourceDetails
                            //         ?.resourceFile?.node?.mediaItemUrl,
                            //     altText: "",
                            // },
                            buttonCode:
                                featuredResource?.resourceDetails?.buttonCode,
                        },
                    } as AcfResourcesBanner;
                case "AcfResources":
                    return {
                        name: block.name,
                        resources: block.resourcesList?.resources?.nodes?.map(
                            (resource: any) => ({
                                content: resource?.content,
                                title: resource?.title,
                                image: {
                                    url: resource?.resourceDetails
                                        ?.resourceImage?.node?.mediaItemUrl,
                                    altText:
                                        resource?.resourceDetails?.resourceImage
                                            ?.node?.mediaItemUrl,
                                },
                                buttonCode:
                                    resource?.resourceDetails?.buttonCode,
                                // file: {
                                //     url: resource?.resourceDetails?.resourceFile
                                //         ?.node?.mediaItemUrl,
                                //     altText: "",
                                // },
                            })
                        ),
                    } as AcfResources;
                case "AcfShoppingMapBanner":
                    return {
                        name: block.name,
                        content: block.shoppingMapBanner?.bannerContent,
                        image: {
                            url: block.shoppingMapBanner?.bannerImage?.node
                                ?.mediaItemUrl,
                            altText:
                                block.shoppingMapBanner?.bannerImage?.node
                                    ?.altText,
                        },
                    } as AcfShoppingMapBanner;
                case "AcfShoppingMaps":
                    return {
                        name: block.name,
                        maps: block.shoppingMaps?.maps?.nodes?.map(
                            (map: any) => ({
                                title: map?.title,
                                link: {
                                    target: "_blank",
                                    url: map?.shoppingMapDetails?.mapLink,
                                    text: map?.title,
                                },
                            })
                        ),
                    } as AcfShoppingMaps;
                case "AcfShoppingMapCta":
                    return {
                        name: block.name,
                        insightsButtonText:
                            block.shoppingMapCTA?.insightsButtonText,
                        newsButtonText: block.shoppingMapCTA?.newsButtonText,
                        superTitle: block.shoppingMapCTA?.superTitle,
                        title: block.shoppingMapCTA?.title,
                        image: {
                            url: block.shoppingMapCTA?.ctaImage?.node
                                ?.mediaItemUrl,
                            altText:
                                block.shoppingMapCTA?.ctaImage?.node?.altText,
                        },
                    } as AcfShoppingMapCta;
                default:
                    return {
                        name: block.name,
                        error: `block format logic not found for ${block.__typename}`,
                    };
            }
        });
    }
}

export default DataUtilities;
