import { useState, useEffect, useRef } from "react";
import "./styles.scss";

interface ImageRotatorProps {
    images: string[];
    pauseIndex: number | null;
}

const ImageRotator = ({ images, pauseIndex }: ImageRotatorProps) => {
    const [index, setIndex] = useState(0);
    const [previousIndex, setPreviousIndex] = useState(0);
    const indexRef = useRef(index);

    useEffect(() => {
        indexRef.current = index;
    }, [index]);

    useEffect(() => {
        if (pauseIndex !== null) {
            setPreviousIndex(indexRef.current);
            setIndex(pauseIndex);
        }
    }, [pauseIndex]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (pauseIndex !== null) return;

            setPreviousIndex(indexRef.current);

            setIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2000);

        return () => clearInterval(interval);
    }, [images.length, pauseIndex]);

    return (
        <div className="bc-image-rotator">
            {images.map((image, i) => (
                <div
                    key={`service-image-${i}`}
                    className={`bc-service-image-container ${
                        i === index ? "active" : ""
                    } ${i === previousIndex ? "previous" : ""}`}
                    style={{ backgroundImage: `url(${image})` }}
                ></div>
            ))}
        </div>
    );
};

export default ImageRotator;
