import Button from "../Buttons/Button";
import FancyButton from "../Buttons/FancyButton";
import ExternalButton from "../Buttons/External";
import BrandCarousel from "../BrandCarousel/BrandCarousel";
import LineGraphAnimation from "../LineGraphAnimation/LineGraphAnimation";
import { motion } from "framer-motion";
import { AcfHomepageUpperCards } from "../../Data/dtos";

const HomepageUpperCards = (props: AcfHomepageUpperCards) => {
    const { header, dashboard, locations, partners } = props;

    return (
        <div className="container mx-auto py-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 grid-rows-auto gap-5">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                    className="card bg-bc-transparent border-2 border-bc-salmon p-6 lg:p-8 2xl:px-10 2xl:py-6 rounded-lg row-span-1 md:row-span-1 lg:row-span-4 col-span-1 order-2 lg:order-1 flex flex-col justify-between"
                >
                    <header>
                        <p className="super-title mb-4">
                            {dashboard.superTitle}
                        </p>
                        <h1 className="text-bc-salmon mb-6">
                            {dashboard.header}
                        </h1>
                        <p>{dashboard.content}</p>
                    </header>
                    <LineGraphAnimation />
                    <footer className="mt-12 flex flex-col gap-2">
                        <p>Access dashboards</p>
                        <div className="grid grid-cols-2 gap-4">
                            {dashboard?.link && (
                                <div className="col-span-1 lg:col-span-2 xl:col-span-1">
                                    <ExternalButton
                                        to={dashboard?.link?.url}
                                        text={dashboard?.link?.title}
                                    />
                                </div>
                            )}
                            {dashboard.link2 && (
                                <div className="col-span-1 lg:col-span-2 xl:col-span-1">
                                    <ExternalButton
                                        to={dashboard?.link2?.url}
                                        text={dashboard?.link2?.title}
                                    />
                                </div>
                            )}
                        </div>
                    </footer>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                    className="flex items-center card bg-bc-deep-blue px-6 py-4 lg:px-8 lg:py-6 2xl:px-10 2xl:py-6 rounded-lg row-span-1 col-span-1 md:col-span-2 lg:col-span-3 order-1 md:order-1 lg:order-2"
                >
                    <h3 className="text-white">{header?.text}</h3>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 }}
                    className="card flex flex-col justify-between bg-bc-chartreuse p-6 lg:p-8 2xl:px-10 2xl:py-6 rounded-lg col-span-1 md:col-span-1 row-span-1 lg:row-span-3 md:row-span-1 order-3"
                >
                    <div>
                        <header className="mb-8">
                            <p className="super-title">{locations?.header}</p>
                        </header>
                        <ul className="mb-4 bc-border-list">
                            {locations.locations?.map((location, index) => (
                                <li className="py-2" key={`location-${index}`}>
                                    <p>{location.title}</p>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <footer className="mt-12 mb-3">
                        <Button
                            size="sm"
                            external={false}
                            to={locations?.link?.url}
                            text={locations?.link?.title}
                        />
                    </footer>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5 }}
                    className="card bg-bc-transparent border-2 border-bc-soft-black py-2 px-6 lg:p-8 2xl:p-10 rounded-lg md:row-span-1 lg:row-span-3 col-span-1 md:col-span-2 order-4 flex flex-col"
                    style={{ minHeight: "16rem" }}
                >
                    <h4 className="text-center w-full mb-2">
                        {partners.header}
                    </h4>
                    <div className="flex items-center h-full">
                        <BrandCarousel
                            logos={partners?.retailers?.map((p) => p.logo)}
                        />
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default HomepageUpperCards;
