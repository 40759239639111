import Section from "../Section/Section";
import { Colors } from "../../Types/Global";
import { AcfCoreValues } from "../../Data/dtos";

interface CoreValue {
    title: string;
    text: string;
}

interface CoreValuesProps {
    superTitle: string;
    header: string;
    body: string;
    values: CoreValue[];
}

const CoreValues = ({
    header,
    superTitle,
    bodyText,
    coreValues,
}: AcfCoreValues) => {
    return (
        <Section>
            <div className="container mx-auto py-12 px-12 bg-bc-main-neutral rounded-lg">
                <div className="flex mb-8">
                    <div className="w-full md:w-1/2 lg:w-1/3">
                        <header className="mb-8 flex flex-col gap-2">
                            <h4>{superTitle}</h4>
                            <h3>{header}</h3>
                        </header>

                        <p>{bodyText}</p>
                    </div>
                </div>

                <div className="pt-12 pb-4">
                    <ul className="grid grid-cols-10 gap-7">
                        {coreValues?.map((val: any, index) => (
                            <li
                                key={`core-val-${index}`}
                                className="col-span-10 md:col-span-5 lg:col-span-2"
                            >
                                <header className="border-b border-bc-soft-black mb-4 pb-4">
                                    <p>{val.label}</p>
                                </header>
                                <h4>{val.valueText}</h4>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Section>
    );
};

export default CoreValues;
