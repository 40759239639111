const LineGraphAnimation = () => {
    return (
        <iframe
            title="line graph graphic"
            className="w-full"
            src="/assets/svg/graph-anim.svg"
        />
    );
};

export default LineGraphAnimation;
