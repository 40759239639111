import NewsPreview from "../NewsPreview/NewsPreview";
import ShopWithUs from "../ShopWithUs/ShopWithUs";
import DropDownButton from "../Buttons/DropdownButton";
import { AcfHomepageCardsLower } from "../../Data/dtos";
import { useModals, Modal } from "../../Providers/Modal";

const HomepageCardsLower = (props: AcfHomepageCardsLower) => {
    const { news, shop, map, reports } = props;
    const Modals = useModals();
    return (
        <div className="container mx-auto py-4 md:py-12">
            <div className="grid grid-cols-12 grid-rows-auto gap-4">
                <div className="flex flex-col justify-between col-span-12 lg:col-span-5 row-span-1 lg:row-span-3 bg-bc-light-blue rounded-lg p-6">
                    <NewsPreview {...news} />
                </div>
                <div className="col-span-12 lg:col-span-7 row-span-2 lg:row-span-3 grid grid-cols-2 grid-rows-auto gap-4">
                    <div className="col-span-2 row-span-2 lg:row-span-1">
                        <ShopWithUs
                            link={shop.link}
                            number={shop.sectionNumber}
                            title={shop.text}
                            mapImage={map.image.url}
                        />
                    </div>
                    <div className="col-span-2 row-span-1 sm:row-span-2 border-2 border-bc-salmon rounded-lg p-6 flex items-center flex-wrap">
                        <div className="w-full md:w-1/2">
                            <header className="flex mb-8 pr-0 md:pr-4">
                                <p className="card-number mr-2">
                                    {reports.sectionNumber}
                                </p>
                                <h4>{reports.header}</h4>
                            </header>
                            <h1 className="text-bc-salmon pr-0 md:pr-8 mb-6">
                                {reports.title}
                            </h1>
                        </div>
                        <div className="w-full md:w-1/2 mb-4">
                            <div
                                className="mb-2 serif"
                                dangerouslySetInnerHTML={{
                                    __html: reports.content,
                                }}
                            ></div>
                            <DropDownButton
                                text="Get access"
                                onClick={() => {
                                    Modals.openModal(Modal.DOWNLOADS);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomepageCardsLower;
