import Section from "../Section/Section";
import Button from "../Buttons/Button";
import TalkIconRed from "../../assets/icons/talkie-red.svg";
import DataUtilities from "../../Data/Utilities";
import { AcfJoinTeam } from "../../Data/dtos";
import "./styles.scss";

const GetInTouch = ({
    header,
    bodyText,
    contactType,
    email,
    boxColor,
    ctaLink,
}: AcfJoinTeam) => {
    const linkUrl = ctaLink?.url
        ? DataUtilities.localizeUrl(ctaLink?.url)
        : "/connect";

    return (
        <Section>
            <div className="container mx-auto py-12">
                <div className="flex">
                    <div className="w-full md:w-1/2">
                        <div className="max-w-lg">
                            <h3 className="mb-4">{header}</h3>
                            <p className="text-sm">{bodyText}</p>
                            <div className="mt-8">
                                <img src={TalkIconRed} />
                            </div>
                        </div>
                    </div>
                    <div
                        className="w-full md:w-1/2 rounded-lg p-4"
                        style={{ backgroundColor: boxColor }}
                    >
                        <div className="bg-bc-soft-white rounded-lg py-8 px-4">
                            <div className="mb-4 pb-4 border-b border-bc-soft-black">
                                <p>{contactType}</p>
                            </div>
                            <div>
                                <a
                                    className="mailto-link text-sm text-bc-soft-black hover:text-bc-bright-blue"
                                    href={`mailto:${email}`}
                                >
                                    {email}
                                </a>
                            </div>
                        </div>
                        <footer className="pt-12 pb-6 text-right pr-2">
                            <Button
                                to={linkUrl}
                                text={ctaLink?.title}
                                target={ctaLink?.target}
                                size="sm"
                            />
                        </footer>
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default GetInTouch;
