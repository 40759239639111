import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
    // const [bcHistory, setBcHistory] = useState<string[]>([]);
    const { pathname } = useLocation();

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        // if (bcHistory.length > 0 && bcHistory.includes(pathname)) {
        //     //the user has been to this page before, so don't worry about scrolling to top
        //     return;
        // }

        // setBcHistory([...bcHistory, pathname]);
        // window.setTimeout(() => {
        // }, 100); // give this a little pause so it happens after the exit transition
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [pathname]);

    return <div></div>;
};

export default ScrollToTop;
