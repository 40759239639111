import { AcfResources } from "../../Data/dtos";
import DownloadButton from "../Buttons/DownloadButton";
import "./styles.scss";

const ResourcesList = ({ resources }: AcfResources) => {
    return (
        <ul className="resources-list grid grid-cols-12 grid-rows-auto gap-8 pb-12">
            {resources?.map((resource, index) => {
                return (
                    <li
                        className="resource-item border-2 border-bc-soft-black rounded-lg col-span-12 lg:col-span-6 xl:col-span-4 overflow-hidden flex flex-col"
                        key={`resource-item-${index}`}
                    >
                        {resource?.image?.url && (
                            <header
                                className="post-image"
                                style={{
                                    backgroundImage: `url(${resource?.image?.url})`,
                                }}
                            ></header>
                        )}
                        <div className="post-content py-8 px-12 flex flex-col justify-between h-full">
                            <div className="h-full pt-4">
                                {/* <h4>{resource?.categories?.join(", ")}</h4> */}
                                <h2 className="serif mb-8 mt-6">
                                    {resource?.title}
                                </h2>
                                <div
                                    className="mt-12"
                                    dangerouslySetInnerHTML={{
                                        __html: resource?.content || "",
                                    }}
                                ></div>
                            </div>

                            <footer className="mt-12">
                                <DownloadButton
                                    buttonCode={resource?.buttonCode}
                                    file={resource?.file}
                                    text="Read on!"
                                />
                            </footer>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default ResourcesList;
