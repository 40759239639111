interface SpacerProps {
    height?: number;
    size?: "sm" | "md" | "lg" | "xl";
}

const Spacer = ({ height, size }: SpacerProps) => {
    const sizes = {
        sm: "h-10",
        md: "h-32",
        lg: "h-60",
        xl: "h-80",
    };

    const sizeClass = size ? sizes[size] : "h-auto";

    return (
        <div
            className={`spacer w-full ${sizeClass}`}
            style={height ? { height: `${height}px` } : {}}
        ></div>
    );
};

export default Spacer;
