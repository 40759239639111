import { HelmetProvider } from "react-helmet-async";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Router from "./Router/Router";
import { useLocation } from "react-router-dom";
import { CompanyDetailsProvider } from "./Providers/CompanyDetails";
import { ModalProvider } from "./Providers/Modal";
import { ApolloProvider } from "@apollo/client";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import client from "./Data/apollo-client";

function App() {
    const location = useLocation();

    return (
        <div className="App">
            <ApolloProvider client={client}>
                <HelmetProvider>
                    <CompanyDetailsProvider>
                        <ModalProvider>
                            <Header location={location} />
                            <main className="main">
                                <Router location={location} />
                            </main>
                            <Footer />
                            <ScrollToTop />
                        </ModalProvider>
                    </CompanyDetailsProvider>
                </HelmetProvider>
            </ApolloProvider>
        </div>
    );
}

export default App;
